/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable no-debugger */
/* eslint-disable react/no-children-prop */
/* eslint-disable sonarjs/no-redundant-jump */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { agrGeneralInfoStyles } from '../../../JSstyles/agrGeneralInfoStyles';
import { paymentCost } from '../../../JSstyles/paymentCost';
import PaymentScheduleCard from '../../Shared/PaymentScheduleCard';
import moment from 'moment';

import {
  Grid,
  Typography,
  RACSelect,
  RACButton,
  Card,
  CardContent,
  CircularProgress,
  RACModalCard,
  RACCOLOR,
  RACTextbox,
  RACInfoIcon
} from '@rentacenter/racstrap';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as Infoicon } from '../../../assets/images/info-tooltip.svg';
import { ReactComponent as Infosus } from '../../../assets/images/infosus.svg';

import CurrencyInput from 'react-currency-input-field';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  getCOpriceQuote,
  GetEmployeeID,
  getAgreementInfo,
  GetRole,
  coCreateAgreement,
  GenerateAgreementDoc,
  tireCreateCart,
  tireCOCreateAgreement
} from '../../../api/user';
import { SetPendingCOAgreementValue } from '../../../app/Routes';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import { useParams, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';
import CustomerSignature from '../../Shared/CustomerSignature';
import { AgreementContext } from '../../../context/AgreementContext';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
import { ReactComponent as Successicon } from '../../../assets/images/success-icon.svg'

import { is } from 'date-fns/locale';
interface Params {
  customerId: string;
  agreementId: string;
}
export default function CustomerOrderAgrDetails({
  CoCustomerDetails: { coCustomerId },
  setItemSearchCompleted,
}) {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const history = useHistory();
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const isTireBuyerFlow = new URLSearchParams(search).get('tbflow')
  const classes = agreementGlobalStyles()
  const agrGeneralInfoClassName = agrGeneralInfoStyles();
  const paymentCostClassName = paymentCost();
  const [errbackupVal, seterrbackupVal] = useState('');
  const { customerId, agreementId } = useParams<Params>();
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const defaultDropdownValue = {
    value: '',
    label: 'Select',
  };
  const initialPaymentAmoutObj = {
    Club: '',
    LDW: '',
    Tax: '',
    Rate: '',
    InitialPayment: '',
  };
  const totalPaymentAmountObj = {
    InitialPayment: '',
    InitialPaymentTerm: '1',
    RegularPayments: '',
    RegularPaymentsTerm: '',
    FinalPayment: '',
    FinalPaymentTerm: '1',
    TotalPayment: '',
    TotalPaymentTerm: '',
    AgreementTotal: '',
  };
  const paymentScheduleObj = {
    weeklyRate: '',
    weeklyTerm: '',
    biweeklyRate: '',
    biweeklyTerm: '',
    semimonthlyRate: '',
    semimonthlyTerm: '',
    monthlyRate: '',
    monthlyTerm: '',
  };
  const selectedScheduleObj = {
    WeeklySchedule: false,
    BiWeeklySchedule: false,
    MonthlySchedule: false,
    SemiMonthlySchedule: false,
  };
  const payScheduleObj = {
    PayschedulePeriod: '',
    PayscheduleDay: '',
  };
  const {
    searchResult,
    setsearchResult,
    setagreementCreateStatus,
    agreementOptions,
    successDropdownValues,
    setsuccessDropdownValues,
    wholeAgrInfoResponse,
    setwholeAgrInfoResponse,
    setAgreementId,
    AgreementId,
    PromoCode,
    addedItems,
    modelNumberArray,
    customerInformation,
    year, setYear,
    make, setMake,
    model, setModel,
    style, setStyle,
    size, setSize,
    tagRate, tagTerm,
    totalCashPrice, setTotalCashPrice,
    agreementTotal, setagreementTotal,
    agreementRate, setAgreementRate,
    agreementTerm, setAgreementTerm,
    salesleadFitemnetid, setsalesleadFitemnetid,
    salesinstallerName, setsalesinstallerName,
    salesinstalldate, setsalesinstalldate,
    salesTimeSlot, setSalesTimeSlot
  } = useContext(CustomerOrderAgreementContext);
  const { setIsAllDone } = useContext(AgreementContext);
  // const PopupCompleteObj = {
  //   VehicleInfoComplete: false,
  //   ConfirmDueDateComplete: false,
  //   AgreementCreated: false,
  //   CustomerSignComplete: false,
  //   PrintPopupComplete: false,
  //   SendAgreementComplete: false,
  //   AutoPayComplete: false,
  //   SMSAutheticationComplete: false,
  //   InitialPaymentComplete: false,
  // };

  const PopupCompleteObj = {
    AgreementCreated: false,
    AgreementSigned: false,
    CustomerSignComplete: false,
    InitialPaymentComplete: false,
    PrelimpaymentPaymentComplete: false,
    AgreementStatus: '',
  };
  const [PopupCompleteStatus, setPopupCompleteStatus] =
    useState(PopupCompleteObj);
  const [processFeeDisable, setprocessFeeDisable] = useState<boolean>(false)
  const [pageLoading, setpageLoading] = useState(true);
  const [scheduleLoading, setscheduleLoading] = useState(true);
  const [editDisableMode, seteditDisableMode] = useState(true);
  const [selectedSchedule, setselectedSchedule] = useState(selectedScheduleObj);
  const [paymentScheduleEnabled, setpaymentScheduleEnabled] = useState(true);
  const [payschedule, setpayschedule] = useState(payScheduleObj);

  const [paymentScheduleValues, setpaymentScheduleValues] =
    useState(paymentScheduleObj);
  // const [AgreementId, setAgreementId] = useState('');
  const [agreementSourceOptions, setagreementSourceOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [scheduleOptions, setscheduleOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [rangeOptions, setrangeOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [offerCodeOptions, setofferCodeOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [optServiceOptions, setoptServiceOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [salesPersonOptions, setsalesPersonOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [ProcessingFeeErrorLbl, setProcessingFeeErrorLbl] = useState(false);
  const [ProcessingFeeLimit, setProcessingFeeLimit] = useState('0.00');
  const [OtherAmt, setOtherAmt] = useState('0.00');
  const [intialAmountCalc, setInitialAmountCalc] = useState('');
  const [ProcessingFeePopupOpen, setProcessingFeePopupOpen] = useState(false);
  const [agreementSourceValue, setagreementSourceValue] = useState('');
  const [scheduleValue, setscheduleValue] = useState('');
  const [rangeValue, setrangeValue] = useState('SEMI1-15');
  const [offerCodeValue, setofferCodeValue] = useState('');
  const [optServiceValue, setoptServiceValue] = useState('');
  const [salesPersonValue, setsalesPersonValue] = useState('');
  const [termValue, settermValue] = useState('');
  const [rangeactive, setrangeactive] = useState(false);
  const [processingFee, setprocessingFee] = useState('0.00');
  const [wlDeposit, setwlDeposit] = useState('0.00');
  const [sacAmount, setsacAmount] = useState();
  const [initialPaymentAmount, setinitialPaymentAmount] = useState(
    initialPaymentAmoutObj
  );
  const [GenerateDocReq, setGenerateDocReq] = useState({});
  const [totalPaymentAmount, settotalPaymentAmount] = useState(
    totalPaymentAmountObj
  );
  const [paymentPopupOpen, setpaymentPopupOpen] = useState(false);
  const [previousBtnDisabled, setpreviousBtnDisabled] = useState(false);
  const [nextBtnDisabled, setnextBtnDisabled] = useState(true);
  const [priceQuoteReq, setpriceQuoteReq] = useState<any>({});
  const [priceQuoteRes, setpriceQuoteRes]: any = useState({});
  const [createAgreementReq, setcreateAgreementReq] = useState({});
  const [somethingWentWrong, setsomethingWentWrong] = useState(false);
  const [apiFailPopupOpen, setapiFailPopupOpen] = useState(false);
  const [AgrSourceDisable, setAgrSourceDisable] = useState(false);
  const [apiFailFirstMessage, setapiFailFirstMessage] = useState('Oops');
  const [apiFailSecondMessage, setapiFailSecondMessage] = useState(
    'Unable to create agreement at this moment'
  );
  const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
  const [createAgrProcessingPopup, setcreateAgrProcessingPopup] =
    useState(false);
  const [RecalculateReq, setRecalculateReq] = useState({});
  const [dropdownLoading, setdropdownLoading] = useState(true);
  const [dropdownLoaded, setdropdownLoaded] = useState(false);
  const CLUB_STATUS_ACTIVE_PENDING = ['A', 'P'];
  const API_ERROR_MESSAGE = 'Unable to fetch the data';
  const [secondFactorEnabled, setsecondFactorEnabled] = useState(false);
  const [currentRole, setcurrentRole] = useState('');
  const [customerSignatureEnabled, setcustomerSignatureEnabled] =
    useState(false);
  const [documentGenerateCompleted, setdocumentGenerateCompleted] =
    useState(false);
  const [AgrCustomerObj, setAgrCustomerObj]: any = useState([]);
  const [CustomerCoverageData, setCustomerCoverageData] = useState();
  const [ClubStatus, setClubStatus] = useState<any>();
  const [ClubOriginStore, setClubOriginStore] = useState();
  const [IsLdwTiedWithClub, setIsLdwTiedWithClub] = useState();
  const [LDWstatus, setLDWstatus] = useState();
  const [AddingBpclubOpen, setAddingBpclubOpen] = useState(false);
  const [DecliningLdwOpen, setDecliningLdwOpen] = useState(false);
  const ManualSignOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ];



  // Tire buyer states
  const [tbSecondFactor, settbSecondFactor] = useState(false)
  const [iframeUrl, setIframeUrl] = useState('');
  const [tbIframePopup, setTbIframePopup] = useState(false)
  const [installationSuccessfull, setinstallationSuccessfull] = useState(false)
  const [getAgreementInfoRes, setgetAgreementInfoRes] = useState<any>('')
  const [normalFlowCustomerSign, setnormalFlowCustomerSign] = useState(false)
  const [tbCustomerSignCancel, settbCustomerSignCancel] = useState(false)
  const [pageLoader, setpageLoader] = useState(false)
  const [schedulledInstallation, setschedulledInstallation] = useState(true)
  const installerInfoObj = {
    installerName: '',
    emailAddress: '',
    externalPoNumber: '',
    phoneNumber: '',
    installationDate: '',
    installationTime: '',
    installerAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: ''
    }
  }
  const [installerInfo, setInstallerInfo] = useState<any>(
    installerInfoObj
  )

  const [vehicleInfo, setvehicleInfo] = useState({
    make: make,
    model: model,
    year: year
  })

  useEffect(() => {
    debugger;
    const priceQuoteReqTemp: any = priceQuoteReq;
    const createAgreementReqTemp: any = createAgreementReq;
    console.log('agreementOptions', agreementOptions);
    console.log('searchResult[0]', searchResult);
    if (
      searchResult != undefined &&
      searchResult.length > 0 &&
      agreementOptions != undefined &&
      agreementId == undefined
    ) {
      // priceQuoteReqTemp.rmsItemNumbers = [searchResult[0].rmsItemNumber];
      priceQuoteReq.rmsItemNumbers = searchResult.map((obj: any) => {
        return obj.rmsItemNumber
      })
      priceQuoteReqTemp.weeklyRate = agreementRate ? agreementRate : searchResult[0].weeklyRate;
      priceQuoteReqTemp.weeklyTerm = agreementTerm ? agreementTerm : searchResult[0].weeklyTerm;
      priceQuoteReqTemp.storeNumber = searchResult[0].storeNumber;
      priceQuoteReqTemp.customerId = customerId;
      priceQuoteReqTemp.promoCode = salesLeadId ? PromoCode : null;
      priceQuoteReqTemp.schedule = null;
      priceQuoteReqTemp.isLDWApplied = true;
      priceQuoteReqTemp.isClubApplied = true;
      priceQuoteReqTemp.cashPrice = searchResult[0]?.cashPrice;
      console.log('priceQuoteReqTemp', priceQuoteReqTemp);
      seterrbackupVal(priceQuoteReqTemp?.promoCode);
      const initialPageload = async () => {
        const getCOpriceQuoteResponse = await getCOpriceQuote(
          priceQuoteReqTemp
        );
        setpageLoading(false);
        setscheduleLoading(false);
        console.log('getCOpriceQuoteResponse', getCOpriceQuoteResponse);
        const getCOpriceQuoteData = getCOpriceQuoteResponse.data;
        //update
        if (getCOpriceQuoteResponse.status == 200) {
          setAgrSourceDisable(true);
          setagreementSourceValue('SPOR');
          setpriceQuoteRes(getCOpriceQuoteResponse.data);
          setdropdownLoading(false);
          seteditDisableMode(false);
          GetPriceQuoteValueBind(getCOpriceQuoteData);
          if (getCOpriceQuoteData.employerPayschedule !== undefined) {
            if (
              getCOpriceQuoteData.employerPayschedule.schedule !== undefined &&
              getCOpriceQuoteData.employerPayschedule.daysPaid !== undefined
            ) {
              setpaymentScheduleEnabled(true);
              if (
                getCOpriceQuoteData.employerPayschedule.schedule.includes(
                  'Semi-Monthly'
                ) ||
                getCOpriceQuoteData.employerPayschedule.schedule.includes(
                  'Monthly'
                )
              ) {
                if (
                  getCOpriceQuoteData.employerPayschedule.daysPaid?.includes('&')
                ) {
                  const arr =
                    getCOpriceQuoteData.employerPayschedule.daysPaid?.split('&');
                  const convertedPayschedule =
                    Addsuffix(arr[0]) + ' & ' + Addsuffix(arr[1]);
                  setpayschedule({
                    ...payschedule,
                    PayschedulePeriod:
                      getCOpriceQuoteData.employerPayschedule.schedule,
                    PayscheduleDay: convertedPayschedule,
                  });
                } else {
                  setpayschedule({
                    ...payschedule,
                    PayschedulePeriod:
                      getCOpriceQuoteData.employerPayschedule.schedule,
                    PayscheduleDay: Addsuffix(
                      getCOpriceQuoteData.employerPayschedule.daysPaid
                    ),
                  });
                }
              } else {
                setpayschedule({
                  ...payschedule,
                  PayschedulePeriod:
                    getCOpriceQuoteData.employerPayschedule.schedule,
                  PayscheduleDay:
                    getCOpriceQuoteData.employerPayschedule.daysPaid,
                });
              }
            } else {
              setpaymentScheduleEnabled(false);
            }
          }
          if (getCOpriceQuoteData.agreementSchedule.schedule.includes('SEMI')) {
            setrangeactive(true);
            setscheduleValue('SEMI1-15');
            setselectedSchedule({
              ...selectedSchedule,
              WeeklySchedule: false,
              BiWeeklySchedule: false,
              MonthlySchedule: false,
              SemiMonthlySchedule: true,
            });
            settermValue(getCOpriceQuoteData.paymentSchedule.semimonthlyTerm);
          } else {
            setrangeactive(false);
            setscheduleValue(getCOpriceQuoteData.agreementSchedule.schedule);
            setselectedSchedule({
              ...selectedSchedule,
              WeeklySchedule:
                getCOpriceQuoteData.agreementSchedule.schedule == 'WK'
                  ? true
                  : false,
              BiWeeklySchedule:
                getCOpriceQuoteData.agreementSchedule.schedule == 'BIWK'
                  ? true
                  : false,
              MonthlySchedule:
                getCOpriceQuoteData.agreementSchedule.schedule == 'MON'
                  ? true
                  : false,
              SemiMonthlySchedule: false,
            });
            if (getCOpriceQuoteData.agreementSchedule.schedule == 'WK') {
              settermValue(getCOpriceQuoteData.paymentSchedule.weeklyTerm);
            } else if (
              getCOpriceQuoteData.agreementSchedule.schedule == 'BIWK'
            ) {
              settermValue(getCOpriceQuoteData.paymentSchedule.biweeklyTerm);
            } else if (
              getCOpriceQuoteData.agreementSchedule.schedule == 'MON'
            ) {
              settermValue(getCOpriceQuoteData.paymentSchedule.monthlyTerm);
            }
          }
          createAgreementReqTemp.warehouseNumber = searchResult[0].wareHouseId;
          // createAgreementReqTemp.orderType = '1';
          createAgreementReqTemp.fees =
            getCOpriceQuoteData.agreementInitialPayment.totalFeesAmount ==
              '0.00'
              ? null
              : [
                {
                  feeAmount:
                    getCOpriceQuoteData.agreementInitialPayment
                      .totalFeesAmount,
                  feeType: 'PROC',
                  feeAmountTax: getCOpriceQuoteData?.agreementInitialPayment
                    ?.totalFeesAmountTax
                },
              ];
          createAgreementReqTemp.storeNumber =
            window.sessionStorage.getItem('storeNumber');
          if (coCustomerId) {
            createAgreementReqTemp.customer = [
              {
                customerId: customerId,
                priority: 1,
              },
              {
                customerId: coCustomerId,
                priority: 2,
              },
            ];
          } else {
            createAgreementReqTemp.customer = [
              {
                customerId: customerId,
                priority: 1,
              },
            ];
          }

          createAgreementReqTemp.hubStoreNumber =
            window.sessionStorage.getItem('storeNumber');
          setIsLdwTiedWithClub(
            getCOpriceQuoteData.storeCoverageStatus.isLdwTiedWithClub
          );
          setLDWstatus(
            getCOpriceQuoteData.customerCoverages.previousAgreementsLdwStatus
          );
          const CustomerCoverageObj: any = {
            storeCoverageStatus: getCOpriceQuoteData.storeCoverageStatus,
            clubInfo: getCOpriceQuoteData.customerCoverages,
          };
          setCustomerCoverageData(CustomerCoverageObj);
          setClubStatus(getCOpriceQuoteData.customerCoverages.clubStatus);
          setClubOriginStore(
            getCOpriceQuoteData.customerCoverages.clubOriginStore
          );
          if (
            CLUB_STATUS_ACTIVE_PENDING.includes(
              getCOpriceQuoteData.customerCoverages.clubStatus
            ) ||
            getCOpriceQuoteData.storeCoverageStatus.clubOnAgreementCreation ===
            false
          ) {
            priceQuoteReq.isClubApplied = false;
            createAgreementReqTemp.isClubApplied = false;
          }
          if (getCOpriceQuoteData.storeCoverageStatus.isLDWAllowed === false) {
            priceQuoteReq.isLDWApplied = false;
            createAgreementReqTemp.isLDWApplied = false;
          }
          if (
            getCOpriceQuoteData.storeCoverageStatus.isLdwTiedWithClub ===
            true &&
            priceQuoteReq.isLDWApplied === false
          ) {
            priceQuoteReq.isClubApplied = false;
            createAgreementReqTemp.isClubApplied = false;
          }
          setcreateAgreementReq(createAgreementReqTemp);
          const GenerateAgrReq: any = {};
          GenerateAgrReq.signingUrlRequired = false;
          GenerateAgrReq.signers = [];
          GenerateAgrReq.documentType = [];
          GenerateAgrReq.returnURL = window.location.origin;
          setGenerateDocReq(GenerateAgrReq);
        } else {
          setsomethingWentWrong(true);
        }
        //Dropdown formation starts here
        const AgrSourceArray = agreementOptions.agreementSource.map(
          (ele: any) => {
            return { value: ele.refCode, label: ele.desc };
          }
        );
        AgrSourceArray.splice(0, 0, defaultDropdownValue);
        const SalesPersonArray = agreementOptions.salesPerson.map(
          (ele: any) => {
            return { value: ele.id.toString(), label: ele.name };
          }
        );
        SalesPersonArray.splice(0, 0, defaultDropdownValue);
        let RawOptionalService = agreementOptions.optServices;
        if (
          CLUB_STATUS_ACTIVE_PENDING.includes(
            getCOpriceQuoteData.customerCoverages.clubStatus
          ) ||
          !getCOpriceQuoteData.storeCoverageStatus.clubOnAgreementCreation
        ) {
          RawOptionalService = RawOptionalService.filter(
            (item) => item.refCode != 'BPC' && item.refCode != 'LDWC'
          );
        }
        if (!getCOpriceQuoteData.storeCoverageStatus.isLDWAllowed) {
          RawOptionalService = RawOptionalService.filter(
            (item) => item.refCode != 'LDW' && item.refCode != 'LDWC'
          );
        }
        if (getCOpriceQuoteData.storeCoverageStatus.isLdwTiedWithClub) {
          RawOptionalService = RawOptionalService.filter(
            (item) => item.refCode != 'BPC'
          );
        }
        console.log('Filtered Optional service', RawOptionalService);
        const optSerArray = RawOptionalService.map((ele: any) => {
          return { value: ele.refCode, label: ele.desc };
        });
        optSerArray.splice(0, 0, defaultDropdownValue);
        const LDWcheck: any = optSerArray.filter((ele) => ele.value === 'LDW');
        const LDWCcheck: any = optSerArray.filter(
          (ele) => ele.value === 'LDWC'
        );

        if (LDWcheck.length > 0 && LDWCcheck.length === 0) {
          console.log('LDW alone check');
          setoptServiceValue('LDW');
        }
        if (LDWCcheck.length > 0) {
          console.log('LDWith club alone check');
          setoptServiceValue('LDWC');
        }
        if (LDWcheck.length === 0 && LDWCcheck.length === 0) {
          setoptServiceValue('NONE');
        }
        const filterCOPromoCode = agreementOptions.storePromotions.filter(
          (ele) => ele.customerOrderEnabled == 1
        );
        if (PromoCode && salesLeadId) {
          const filterPromo = filterCOPromoCode.some(
            (ele) => ele.promotionCode == PromoCode
          );
          if (filterPromo === true) {
            setofferCodeValue(PromoCode);
          }
        }
        const PromoCodeOptionsArray: any = filterCOPromoCode.map((ele: any) => {
          return { value: ele.promotionCode, label: ele.promotionName };
        });
        PromoCodeOptionsArray.splice(0, 0, defaultDropdownValue);

        /* Filter customer order enabled Customer Promotions */
        const filterCOCustPromos = agreementOptions.customerPromotions?.filter(
          (ele) => Number(ele.customerOrderEnabled) == 1
        );

        if(filterCOCustPromos != undefined && filterCOCustPromos.length > 0){
          const customerPromotions = filterCOCustPromos.map((e: any) =>{
            return { value: e.promotionCode, label: e.promotionName };
          })
          console.log('customerPromotions', customerPromotions);
          PromoCodeOptionsArray.push(...customerPromotions);
        }

        const RangeRawValueArray = agreementOptions.schedule.filter(
          (word) => word.desc === 'Semi-Monthly' && word.refCode !== 'SEMI'
        );
        const SortRangeRawValueArray = RangeRawValueArray.sort(
          (a, b) => parseInt(a.displaySeq) - parseInt(b.displaySeq)
        );
        const Rangeval = SortRangeRawValueArray.map((ele: any) => {
          const value = ele.refCode;
          const tempval = value.replace(/SEMI/g, '');
          const tempArr = tempval.split('-');
          const finalrange =
            Addsuffix(tempArr[0]) + ' ' + Addsuffix(tempArr[1]);
          return { value: ele.refCode, label: finalrange };
        });
        const UniqScheduleOptions = agreementOptions.schedule.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.desc === value.desc)
        );
        const ScheduleArray: any = UniqScheduleOptions.map((ele: any) => {
          return { value: ele.refCode, label: ele.desc };
        });
        setscheduleOptions(ScheduleArray);
        setrangeOptions(Rangeval);
        setagreementSourceOptions(AgrSourceArray);
        setofferCodeOptions(PromoCodeOptionsArray);
        setoptServiceOptions(optSerArray);
        setsalesPersonOptions(SalesPersonArray);
      };
      initialPageload();
    } else if (wholeAgrInfoResponse) {
      console.log('2h9whole', wholeAgrInfoResponse);
      setprocessFeeDisable(true);
      seteditDisableMode(true);
      setpageLoading(false);
      setscheduleLoading(false);
      setdropdownLoading(false);
      setAgrCustomerObj(wholeAgrInfoResponse.customers);
      const AgrCustomers = wholeAgrInfoResponse.customers;
      const Customerdata = AgrCustomers.customer.filter(
        (obj) => obj.priority == 1
      );
      console.log('Customerdata', Customerdata);

      const CustomerName =
        Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
      if (AgrCustomers.customer.length == 1) {
        const AGreementDocObj: any = GenerateDocReq;
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
            emailId: Customerdata[0].emailAddress,
            priority: 1,
          },
        ];

        console.log('PayloadForm', CustomerObj);
        setAgrCustomerObj(CustomerObj);
        AGreementDocObj.signers = CustomerObj;
        setGenerateDocReq(AGreementDocObj);
        //setcustomerSignatureEnabled(true);
      } else {
        const AGreementDocObj: any = GenerateDocReq;
        const CoCustomer = AgrCustomers.coCustomer?.filter(
          (obj) => obj.priority == 2
        );
        const CoCustomerName =
          CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
            emailId: Customerdata[0].emailAddress,
            priority: 1,
          },
          {
            customerId: CoCustomer[0].customerId.toString(),
            customerName: CoCustomerName,
            phoneNumber: CoCustomer[0]?.phoneNumbers ? CoCustomer[0]?.phoneNumbers : CoCustomer[0]?.phoneNumber,
            emailId: CoCustomer[0].emailAddress,
            priority: 2,
          },
        ];
        setAgrCustomerObj(CustomerObj);
        AGreementDocObj.signers = CustomerObj;
        setGenerateDocReq(AGreementDocObj);
        //setcustomerSignatureEnabled(true);
      }
      const AgreementDocReq: any = GenerateDocReq;
      AgreementDocReq.identifier = parseInt(wholeAgrInfoResponse.agreementId);
      AgreementDocReq.identifierType = 'AGR';
      AgreementDocReq.signatureType = 1;
      AgreementDocReq.isRemote = false;
      AgreementDocReq.manualSignatureReasonId = null;
      if (AgrCustomers.customer.length === 1) {
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber:
              Customerdata[0].phoneNumber !== null
                ? Customerdata[0].phoneNumber
                : '1111111111',
            emailId:
              Customerdata[0].emailAddress !== null
                ? Customerdata[0].emailAddress
                : 'test@example.com',
            priority: 1,
          },
        ];
        AgreementDocReq.signers = CustomerObj;
      } else {
        const CoCustomer = AgrCustomers.coCustomer?.filter(
          (obj) => obj.priority == 2
        );
        const CoCustomerName =
          CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber:
              Customerdata[0].phoneNumber !== null
                ? Customerdata[0].phoneNumber
                : '1111111111',
            emailId:
              Customerdata[0].emailAddress !== null
                ? Customerdata[0].emailAddress
                : 'test@example.com',
            priority: 1,
          },
          {
            customerId: CoCustomer[0].customerId.toString(),
            customerName: CoCustomerName,
            phoneNumber:
              CoCustomer[0].phoneNumber !== null
                ? CoCustomer[0].phoneNumber
                : '1111111111',
            emailId:
              CoCustomer[0].emailAddress !== null
                ? CoCustomer[0].emailAddress
                : 'test@example.com',
            priority: 2,
          },
        ];
        AgreementDocReq.signers = CustomerObj;
      }
      // if (AutoPayToggleStatus !== true) {
      //   setAutoPayOpen(true);
      // } else {
      //   setcustomerSignatureEnabled(true);
      // }
      //setPreviousBtnDisabled(true);
      // const generate = async () => {
      //   const GenerateAgreementDocRes = await GenerateAgreementDoc(
      //     AgreementDocReq
      //   );
      //   console.log(
      //     'First Generate Agreement Call response',
      //     GenerateAgreementDocRes
      //   );
      // };
      // generate();
      setdocumentGenerateCompleted(true);
      setItemSearchCompleted(true);
      setPopupCompleteStatus({
        ...PopupCompleteStatus,
        CustomerSignComplete:
          wholeAgrInfoResponse.agreementWorkflow.isAgreementSigned == '1',
        PrelimpaymentPaymentComplete:
          wholeAgrInfoResponse.agreementWorkflow.isPreliminaryPaymentPaid ==
          '1',
        InitialPaymentComplete:
          wholeAgrInfoResponse.agreementWorkflow.isInitialPaymentPaid == '1',
        AgreementCreated: true,
        AgreementStatus:
          wholeAgrInfoResponse?.generalInformation?.agreementStatus,
      });
      GetPriceQuoteValueBind(wholeAgrInfoResponse);
      if (wholeAgrInfoResponse.employerPayschedule !== undefined) {
        if (
          wholeAgrInfoResponse.employerPayschedule.schedule &&
          wholeAgrInfoResponse.employerPayschedule.daysPaid
        ) {
          setpaymentScheduleEnabled(true);
          if (
            wholeAgrInfoResponse.employerPayschedule.schedule.includes(
              'Semi-Monthly'
            ) ||
            wholeAgrInfoResponse.employerPayschedule.schedule.includes(
              'Monthly'
            )
          ) {
            if (
              wholeAgrInfoResponse.employerPayschedule.daysPaid.includes('&')
            ) {
              const arr =
                wholeAgrInfoResponse.employerPayschedule.daysPaid.split('&');
              const convertedPayschedule =
                Addsuffix(arr[0]) + ' & ' + Addsuffix(arr[1]);
              setpayschedule({
                ...payschedule,
                PayschedulePeriod:
                  wholeAgrInfoResponse.employerPayschedule.schedule,
                PayscheduleDay: convertedPayschedule,
              });
            } else {
              setpayschedule({
                ...payschedule,
                PayschedulePeriod:
                  wholeAgrInfoResponse.employerPayschedule.schedule,
                PayscheduleDay: Addsuffix(
                  wholeAgrInfoResponse.employerPayschedule.daysPaid
                ),
              });
            }
          } else {
            setpayschedule({
              ...payschedule,
              PayschedulePeriod:
                wholeAgrInfoResponse.employerPayschedule.schedule,
              PayscheduleDay: wholeAgrInfoResponse.employerPayschedule.daysPaid,
            });
          }
        } else {
          setpaymentScheduleEnabled(false);
        }
      }
      if (wholeAgrInfoResponse.agreementSchedule.schedule.includes('SEMI')) {
        setrangeactive(true);
        setscheduleValue('SEMI1-15');
        setselectedSchedule({
          ...selectedSchedule,
          WeeklySchedule: false,
          BiWeeklySchedule: false,
          MonthlySchedule: false,
          SemiMonthlySchedule: true,
        });
        settermValue(wholeAgrInfoResponse.paymentSchedule.semimonthlyTerm);
      } else {
        setrangeactive(false);
        setscheduleValue(wholeAgrInfoResponse.agreementSchedule.schedule);
        setselectedSchedule({
          ...selectedSchedule,
          WeeklySchedule:
            wholeAgrInfoResponse.agreementSchedule.schedule == 'WK'
              ? true
              : false,
          BiWeeklySchedule:
            wholeAgrInfoResponse.agreementSchedule.schedule == 'BIWK'
              ? true
              : false,
          MonthlySchedule:
            wholeAgrInfoResponse.agreementSchedule.schedule == 'MON'
              ? true
              : false,
          SemiMonthlySchedule: false,
        });
        if (wholeAgrInfoResponse.agreementSchedule.schedule == 'WK') {
          settermValue(wholeAgrInfoResponse.paymentSchedule.weeklyTerm);
        } else if (wholeAgrInfoResponse.agreementSchedule.schedule == 'BIWK') {
          settermValue(wholeAgrInfoResponse.paymentSchedule.biweeklyTerm);
        } else if (wholeAgrInfoResponse.agreementSchedule.schedule == 'MON') {
          settermValue(wholeAgrInfoResponse.paymentSchedule.monthlyTerm);
        }
      }
      const AgrSourceArray =
        wholeAgrInfoResponse.agreementOption.agreementSource.map((ele: any) => {
          return { value: ele.value, label: ele.label };
        });
      AgrSourceArray.splice(0, 0, defaultDropdownValue);
      const SalesPersonArray =
        wholeAgrInfoResponse.agreementOption.salesPerson.map((ele: any) => {
          return { value: ele.value.toString(), label: ele.label };
        });
      SalesPersonArray.splice(0, 0, defaultDropdownValue);
      const RawOptionalService =
        wholeAgrInfoResponse.agreementOption.optServices;

      console.log('Filtered Optional service', RawOptionalService);
      const optSerArray = RawOptionalService.map((ele: any) => {
        return { value: ele.value, label: ele.label };
      });
      optSerArray.splice(0, 0, defaultDropdownValue);
      const PromoCodeOptionsArray: any =
        wholeAgrInfoResponse.agreementOption.promoCode?.map((ele: any) => {
          return { value: ele.value, label: ele.label };
        });
      PromoCodeOptionsArray?.splice(0, 0, defaultDropdownValue);
      const RangeRawValueArray =
        wholeAgrInfoResponse.agreementOption.schedule.filter(
          (word) => word.label === 'Semi-Monthly' && word.value !== 'SEMI'
        );
      // eslint-disable-next-line sonarjs/no-identical-functions
      const Rangeval = RangeRawValueArray?.map((ele: any) => {
        const value = ele.value;
        const tempval = value.replace(/SEMI/g, '');
        const tempArr = tempval.split('-');
        const finalrange = Addsuffix(tempArr[0]) + ' ' + Addsuffix(tempArr[1]);
        return { value: ele.value, label: finalrange };
      });
      setscheduleOptions(wholeAgrInfoResponse.agreementOption.schedule);
      setrangeOptions(Rangeval);
      setagreementSourceOptions(
        wholeAgrInfoResponse.agreementOption.agreementSource
      );
      const LDWcheck: any =
        wholeAgrInfoResponse.agreementOption.optServices.filter(
          (ele) => ele.value === 'LDW'
        );
      const LDWCcheck: any =
        wholeAgrInfoResponse.agreementOption.optServices.filter(
          (ele) => ele.value === 'LDWC'
        );
      if (LDWcheck.length > 0 && LDWCcheck.length === 0) {
        console.log('LDW alone check');
        setoptServiceValue('LDW');
      }
      if (LDWCcheck.length > 0) {
        console.log('LDWith club alone check');
        setoptServiceValue('LDWC');
      }
      if (LDWcheck.length === 0 && LDWCcheck.length === 0) {
        setoptServiceValue('NONE');
      }
      setAgrSourceDisable(true);
      setagreementSourceValue('SPOR');
      setAgreementId(wholeAgrInfoResponse.agreementId);
      setofferCodeOptions(PromoCodeOptionsArray);
      setoptServiceOptions(wholeAgrInfoResponse.agreementOption.optServices);
      setsalesPersonOptions(SalesPersonArray);
      wholeAgrInfoResponse.agreementOption.salesPerson.map((el: any) => {
        setsalesPersonValue(el.value);
      });
      if (wholeAgrInfoResponse.generalInformation.agreementStatusRefCode == 'PRELIMCANC') {
        setnextBtnDisabled(true);
      }
      else {
        setnextBtnDisabled(false);
      }

    } else if (agreementId) {
      console.log('2h9whole', wholeAgrInfoResponse);
      setprocessFeeDisable(true);
      const AgrInfoResponse = async () => {
        seteditDisableMode(true);
        setpageLoading(true);
        setscheduleLoading(true);
        setdropdownLoading(true);
        const result = await getAgreementInfo(agreementId);
        if (result.status == 200) {
          const FormatResponse = SetPendingCOAgreementValue(result?.data);
          setwholeAgrInfoResponse(FormatResponse);
          const rmsItemInformation = result?.data?.agreementInventories.map(
            (el: any) => {
              return {
                modelNumber: el.modelNumber,
                brand: el.brand,
                cashPrice: result?.data?.totalPayment.cashPrice,
                department: el.department,
                subdepartment: el.subDepartment,
                monthlyRate: el.agreementRate,
                monthlyTerm: el.agreementTerm,
                totalCost: el.agreementTotal,
                availability: null,
              };
            }
          );
          const rmsItemObj = {
            rmsItemInformation: rmsItemInformation,
          };
          setagreementCreateStatus({ agreementCreatedStatus: true });
          setIsAllDone(true);
          setsearchResult(rmsItemObj.rmsItemInformation);
          if (FormatResponse) {
            seteditDisableMode(true);
            setpageLoading(false);
            setscheduleLoading(false);
            setdropdownLoading(false);
            setAgrCustomerObj(FormatResponse.customers);
            const AgrCustomers = FormatResponse.customers;
            const Customerdata = AgrCustomers.customer.filter(
              (obj) => obj.priority == 1
            );
            console.log('Customerdata', Customerdata);

            const CustomerName =
              Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
            if (AgrCustomers.customer.length == 1) {
              const AGreementDocObj: any = GenerateDocReq;
              const CustomerObj = [
                {
                  customerId: Customerdata[0].customerId.toString(),
                  customerName: CustomerName,
                  phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
                  emailId: Customerdata[0].emailAddress,
                  priority: 1,
                },
              ];

              console.log('PayloadForm', CustomerObj);
              setAgrCustomerObj(CustomerObj);
              AGreementDocObj.signers = CustomerObj;
              setGenerateDocReq(AGreementDocObj);
              //setcustomerSignatureEnabled(true);
            } else {
              const AGreementDocObj: any = GenerateDocReq;
              const CoCustomer = AgrCustomers.coCustomer?.filter(
                (obj) => obj.priority == 2
              );
              const CoCustomerName =
                CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
              const CustomerObj = [
                {
                  customerId: Customerdata[0].customerId.toString(),
                  customerName: CustomerName,
                  phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
                  emailId: Customerdata[0].emailAddress,
                  priority: 1,
                },
                {
                  customerId: CoCustomer[0].customerId.toString(),
                  customerName: CoCustomerName,
                  phoneNumber: CoCustomer[0]?.phoneNumbers ? CoCustomer[0]?.phoneNumbers : CoCustomer[0]?.phoneNumber,
                  emailId: CoCustomer[0].emailAddress,
                  priority: 2,
                },
              ];
              setAgrCustomerObj(CustomerObj);
              AGreementDocObj.signers = CustomerObj;
              setGenerateDocReq(AGreementDocObj);
              //setcustomerSignatureEnabled(true);
            }
            const AgreementDocReq: any = GenerateDocReq;
            AgreementDocReq.identifier = parseInt(FormatResponse.agreementId);
            AgreementDocReq.identifierType = 'AGR';
            AgreementDocReq.signatureType = 1;
            AgreementDocReq.isRemote = false;
            AgreementDocReq.manualSignatureReasonId = null;
            if (AgrCustomers.customer.length === 1) {
              const CustomerObj = [
                {
                  customerId: Customerdata[0].customerId.toString(),
                  customerName: CustomerName,
                  phoneNumber:
                    Customerdata[0].phoneNumber !== null
                      ? Customerdata[0].phoneNumber
                      : '1111111111',
                  emailId:
                    Customerdata[0].emailAddress !== null
                      ? Customerdata[0].emailAddress
                      : 'test@example.com',
                  priority: 1,
                },
              ];
              AgreementDocReq.signers = CustomerObj;
            } else {
              const CoCustomer = AgrCustomers.coCustomer?.filter(
                (obj) => obj.priority == 2
              );
              const CoCustomerName =
                CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

              const CustomerObj = [
                {
                  customerId: Customerdata[0].customerId.toString(),
                  customerName: CustomerName,
                  phoneNumber:
                    Customerdata[0].phoneNumber !== null
                      ? Customerdata[0].phoneNumber
                      : '1111111111',
                  emailId:
                    Customerdata[0].emailAddress !== null
                      ? Customerdata[0].emailAddress
                      : 'test@example.com',
                  priority: 1,
                },
                {
                  customerId: CoCustomer[0].customerId.toString(),
                  customerName: CoCustomerName,
                  phoneNumber:
                    CoCustomer[0].phoneNumber !== null
                      ? CoCustomer[0].phoneNumber
                      : '1111111111',
                  emailId:
                    CoCustomer[0].emailAddress !== null
                      ? CoCustomer[0].emailAddress
                      : 'test@example.com',
                  priority: 2,
                },
              ];
              AgreementDocReq.signers = CustomerObj;
            }
            // if (AutoPayToggleStatus !== true) {
            //   setAutoPayOpen(true);
            // } else {
            //   setcustomerSignatureEnabled(true);
            // }
            //setpreviousBtnDisabled(true);
            // eslint-disable-next-line sonarjs/no-identical-functions
            // const generate = async () => {
            //   const GenerateAgreementDocRes = await GenerateAgreementDoc(
            //     AgreementDocReq
            //   );
            //   console.log(
            //     'First Generate Agreement Call response',
            //     GenerateAgreementDocRes
            //   );
            // };
            // generate();

            setdocumentGenerateCompleted(true);
            setPopupCompleteStatus({
              ...PopupCompleteStatus,
              CustomerSignComplete:
                FormatResponse.agreementWorkflow.isAgreementSigned == '1',
              PrelimpaymentPaymentComplete:
                FormatResponse.agreementWorkflow.isPreliminaryPaymentPaid ==
                '1',
              InitialPaymentComplete:
                FormatResponse.agreementWorkflow.isInitialPaymentPaid == '1',
              AgreementCreated: true,
              AgreementStatus:
                FormatResponse?.generalInformation?.agreementStatus,
            });
            GetPriceQuoteValueBind(FormatResponse, rmsItemObj);
            if (FormatResponse.employerPayschedule !== undefined) {
              if (
                FormatResponse.employerPayschedule.schedule &&
                FormatResponse.employerPayschedule.daysPaid
              ) {
                setpaymentScheduleEnabled(true);
                if (
                  FormatResponse.employerPayschedule.schedule.includes(
                    'Semi-Monthly'
                  ) ||
                  FormatResponse.employerPayschedule.schedule.includes(
                    'Monthly'
                  )
                ) {
                  if (
                    FormatResponse.employerPayschedule.daysPaid.includes('&')
                  ) {
                    const arr =
                      FormatResponse.employerPayschedule.daysPaid.split('&');
                    const convertedPayschedule =
                      Addsuffix(arr[0]) + ' & ' + Addsuffix(arr[1]);
                    setpayschedule({
                      ...payschedule,
                      PayschedulePeriod:
                        FormatResponse.employerPayschedule.schedule,
                      PayscheduleDay: convertedPayschedule,
                    });
                  } else {
                    setpayschedule({
                      ...payschedule,
                      PayschedulePeriod:
                        FormatResponse.employerPayschedule.schedule,
                      PayscheduleDay: Addsuffix(
                        FormatResponse.employerPayschedule.daysPaid
                      ),
                    });
                  }
                } else {
                  setpayschedule({
                    ...payschedule,
                    PayschedulePeriod:
                      FormatResponse.employerPayschedule.schedule,
                    PayscheduleDay: FormatResponse.employerPayschedule.daysPaid,
                  });
                }
              } else {
                setpaymentScheduleEnabled(false);
              }
            }
            if (FormatResponse.agreementSchedule.schedule.includes('SEMI')) {
              setrangeactive(true);
              setscheduleValue('SEMI1-15');
              setselectedSchedule({
                ...selectedSchedule,
                WeeklySchedule: false,
                BiWeeklySchedule: false,
                MonthlySchedule: false,
                SemiMonthlySchedule: true,
              });
              settermValue(FormatResponse.paymentSchedule.semimonthlyTerm);
            } else {
              setrangeactive(false);
              setscheduleValue(FormatResponse.agreementSchedule.schedule);
              setselectedSchedule({
                ...selectedSchedule,
                WeeklySchedule:
                  FormatResponse.agreementSchedule.schedule == 'WK'
                    ? true
                    : false,
                BiWeeklySchedule:
                  FormatResponse.agreementSchedule.schedule == 'BIWK'
                    ? true
                    : false,
                MonthlySchedule:
                  FormatResponse.agreementSchedule.schedule == 'MON'
                    ? true
                    : false,
                SemiMonthlySchedule: false,
              });
              if (FormatResponse.agreementSchedule.schedule == 'WK') {
                settermValue(FormatResponse.paymentSchedule.weeklyTerm);
              } else if (FormatResponse.agreementSchedule.schedule == 'BIWK') {
                settermValue(FormatResponse.paymentSchedule.biweeklyTerm);
              } else if (FormatResponse.agreementSchedule.schedule == 'MON') {
                settermValue(FormatResponse.paymentSchedule.monthlyTerm);
              }
            }
            const AgrSourceArray =
              FormatResponse.agreementOption.agreementSource.map((ele: any) => {
                return { value: ele.value, label: ele.label };
              });
            AgrSourceArray.splice(0, 0, defaultDropdownValue);
            const SalesPersonArray =
              FormatResponse.agreementOption.salesPerson.map((ele: any) => {
                return { value: ele.value.toString(), label: ele.label };
              });
            SalesPersonArray.splice(0, 0, defaultDropdownValue);
            const RawOptionalService =
              FormatResponse.agreementOption.optServices;

            console.log('Filtered Optional service', RawOptionalService);
            const optSerArray = RawOptionalService.map((ele: any) => {
              return { value: ele.value, label: ele.label };
            });
            optSerArray.splice(0, 0, defaultDropdownValue);
            const PromoCodeOptionsArray: any =
              FormatResponse.agreementOption.promoCode?.map((ele: any) => {
                return { value: ele.value, label: ele.label };
              });
            PromoCodeOptionsArray?.splice(0, 0, defaultDropdownValue);
            const RangeRawValueArray =
              FormatResponse.agreementOption.schedule.filter(
                (word) => word.label === 'Semi-Monthly' && word.value !== 'SEMI'
              );
            // eslint-disable-next-line sonarjs/no-identical-functions
            const Rangeval = RangeRawValueArray?.map((ele: any) => {
              const value = ele.value;
              const tempval = value.replace(/SEMI/g, '');
              const tempArr = tempval.split('-');
              const finalrange =
                Addsuffix(tempArr[0]) + ' ' + Addsuffix(tempArr[1]);
              return { value: ele.value, label: finalrange };
            });
            setscheduleOptions(FormatResponse.agreementOption.schedule);
            setrangeOptions(Rangeval);
            setagreementSourceOptions(
              FormatResponse.agreementOption.agreementSource
            );
            const LDWcheck: any =
              FormatResponse.agreementOption.optServices.filter(
                (ele) => ele.value === 'LDW'
              );
            const LDWCcheck: any =
              FormatResponse.agreementOption.optServices.filter(
                (ele) => ele.value === 'LDWC'
              );
            if (LDWcheck.length > 0 && LDWCcheck.length === 0) {
              console.log('LDW alone check');
              setoptServiceValue('LDW');
            }
            if (LDWCcheck.length > 0) {
              console.log('LDWith club alone check');
              setoptServiceValue('LDWC');
            }
            if (LDWcheck.length === 0 && LDWCcheck.length === 0) {
              setoptServiceValue('NONE');
            }
            setAgrSourceDisable(true);
            setagreementSourceValue('SPOR');

            setAgreementId(FormatResponse.agreementId);
            setofferCodeOptions(PromoCodeOptionsArray);
            setoptServiceOptions(FormatResponse.agreementOption.optServices);
            setsalesPersonOptions(SalesPersonArray);
            FormatResponse.agreementOption.salesPerson.map((el: any) => {
              setsalesPersonValue(el.value);
            });
            if (FormatResponse.generalInformation.agreementStatusRefCode == 'PRELIMCANC') {
              setnextBtnDisabled(true);
            }
            else {
              setnextBtnDisabled(false);
            }
            //setnextBtnDisabled(false);
          }
        } else {
          setsomethingWentWrong(true);
        }
      };
      AgrInfoResponse();
    } else {
      setsomethingWentWrong(true);
    }
    setpriceQuoteReq(priceQuoteReqTemp);
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRoleRes = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRoleRes);
          setcurrentRole(currentRoleRes);
        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setcurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, []);
  useEffect(() => {
    console.log('intialAmountCalc', intialAmountCalc);
    console.log('processingFee', processingFee);
    console.log('OtherAmt', OtherAmt);

    if (intialAmountCalc && processingFee != OtherAmt) {
      const calcFees: any = Number(
        Number(intialAmountCalc) -
        Number(Number(processingFee) - Number(OtherAmt))
      );
      console.log('calcFees', calcFees);
      //const fees: any[] = []; //

      const fee: any = [
        {
          feeAmount: OtherAmt.toString(),
          feeType: 'PROC',
        },
      ];

      //fees.push(fee);
      setcreateAgreementReq({ ...createAgreementReq, fees: fee });
      setinitialPaymentAmount({
        ...initialPaymentAmount,
        InitialPayment: calcFees.toString(),
      });
    }
  }, [OtherAmt]);
  useEffect(() => {

    if (agreementId) {
      getAgreementInfoFn()

    }
  }, [])

  const getAgreementInfoFn = async () => {
    const getAgreementInfoFnRes = await getAgreementInfo(agreementId)
    if (getAgreementInfoFnRes.status == 200) {
      setgetAgreementInfoRes(getAgreementInfoFnRes?.data)
    }
  }
  const GetPriceQuoteValueBind = (getCOpriceQuoteData: any, value?: any) => {
    if (searchResult != undefined) {
      setpaymentScheduleValues(getCOpriceQuoteData.paymentSchedule);
      setinitialPaymentAmount({
        ...initialPaymentAmount,
        Club: getCOpriceQuoteData.agreementInitialPayment.clubAmount,
        LDW: getCOpriceQuoteData.agreementInitialPayment.ldw,
        Tax: getCOpriceQuoteData.agreementInitialPayment.totalTax,
        Rate: getCOpriceQuoteData.agreementInitialPayment.initialRate,
        InitialPayment:
          getCOpriceQuoteData.agreementInitialPayment.initialPayment,
      });
      setprocessingFee(
        getCOpriceQuoteData.agreementInitialPayment.totalFeesAmount
      );
      setwlDeposit(getCOpriceQuoteData.agreementInitialPayment?.webLeadDeposit);
      setInitialAmountCalc(
        getCOpriceQuoteData.agreementInitialPayment.initialPayment
      );
      settotalPaymentAmount({
        ...totalPaymentAmount,
        InitialPayment:
          getCOpriceQuoteData.agreementTotalPayment.initialPaymentTotal,
        RegularPayments:
          getCOpriceQuoteData.agreementTotalPayment.regularPaymentsAmount,
        RegularPaymentsTerm:
          getCOpriceQuoteData.agreementTotalPayment.regularPaymentsCount,
        FinalPayment: getCOpriceQuoteData.agreementTotalPayment.finalPayment,
        TotalPayment: getCOpriceQuoteData.agreementTotalPayment.totalPayments,
        AgreementTotal: getCOpriceQuoteData.agreementTotalPayment?.totalCost ? getCOpriceQuoteData.agreementTotalPayment.totalCost : agreementTotal ? agreementTotal : searchResult[0].totalCost,
        TotalPaymentTerm:
          getCOpriceQuoteData.agreementTotalPayment.totalPayments,
      });
      setOtherAmt(getCOpriceQuoteData.agreementInitialPayment.totalFeesAmount);
      setsacAmount(getCOpriceQuoteData?.agreementTotalPayment?.cashPrice ? getCOpriceQuoteData?.agreementTotalPayment?.cashPrice : totalCashPrice ? totalCashPrice : searchResult[0].cashPrice);
    } else if (searchResult == undefined && wholeAgrInfoResponse) {
      //
      setpaymentScheduleValues(getCOpriceQuoteData.paymentSchedule);
      setinitialPaymentAmount({
        ...initialPaymentAmount,
        Club: getCOpriceQuoteData.agreementInitialPayment.clubAmount,
        LDW: getCOpriceQuoteData.agreementInitialPayment.ldw,
        Tax: getCOpriceQuoteData.agreementInitialPayment.totalTax,
        Rate: getCOpriceQuoteData.agreementInitialPayment.initialRate,
        InitialPayment:
          getCOpriceQuoteData.agreementInitialPayment.initialPayment,
      });
      settotalPaymentAmount({
        ...totalPaymentAmount,
        InitialPayment:
          getCOpriceQuoteData.agreementTotalPayment.initialPaymentTotal,
        RegularPayments:
          getCOpriceQuoteData.agreementTotalPayment.regularPaymentsAmount,
        RegularPaymentsTerm:
          getCOpriceQuoteData.agreementTotalPayment.regularPaymentsCount,
        FinalPayment: getCOpriceQuoteData.agreementTotalPayment.finalPayment,
        TotalPayment: getCOpriceQuoteData.agreementTotalPayment.totalPayments,
        AgreementTotal: getCOpriceQuoteData?.agreementTotalPayment?.totalCost ? getCOpriceQuoteData?.agreementTotalPayment?.totalCost : getCOpriceQuoteData.agreementTotal,
        TotalPaymentTerm:
          getCOpriceQuoteData.agreementTotalPayment.totalPayments,
      });
      setsacAmount(getCOpriceQuoteData?.agreementTotalPayment?.cashPrice ? getCOpriceQuoteData?.agreementTotalPayment?.cashPrice : getCOpriceQuoteData.agreementTotalPayment.sacTotal);
    }
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    else if (value != undefined) {
      setpaymentScheduleValues(getCOpriceQuoteData.paymentSchedule);
      setinitialPaymentAmount({
        ...initialPaymentAmount,
        Club: getCOpriceQuoteData.agreementInitialPayment.clubAmount,
        LDW: getCOpriceQuoteData.agreementInitialPayment.ldw,
        Tax: getCOpriceQuoteData.agreementInitialPayment.totalTax,
        Rate: getCOpriceQuoteData.agreementInitialPayment.initialRate,
        InitialPayment:
          getCOpriceQuoteData.agreementInitialPayment.initialPayment,
      });

      settotalPaymentAmount({
        ...totalPaymentAmount,
        InitialPayment:
          getCOpriceQuoteData.agreementTotalPayment.initialPaymentTotal,
        RegularPayments:
          getCOpriceQuoteData.agreementTotalPayment.regularPaymentsAmount,
        RegularPaymentsTerm:
          getCOpriceQuoteData.agreementTotalPayment.regularPaymentsCount,
        FinalPayment: getCOpriceQuoteData.agreementTotalPayment.finalPayment,
        TotalPayment: getCOpriceQuoteData.agreementTotalPayment.totalPayments,
        AgreementTotal: getCOpriceQuoteData?.agreementTotalPayment?.totalCost ? getCOpriceQuoteData?.agreementTotalPayment?.totalCost : getCOpriceQuoteData.agreementTotal,
        TotalPaymentTerm:
          getCOpriceQuoteData.agreementTotalPayment.totalPayments,
      });
      setsacAmount(getCOpriceQuoteData?.agreementTotalPayment?.cashPrice ? getCOpriceQuoteData?.agreementTotalPayment?.cashPrice : getCOpriceQuoteData.agreementTotalPayment.sacTotal);
    }
  };
  const Addsuffix = (value) => {
    const covertvalue = parseInt(value);
    if (covertvalue < 1000) {
      const FirstCheck = covertvalue % 10;
      const SecondCheck = covertvalue % 100;
      if (FirstCheck == 1 && SecondCheck != 11) {
        return covertvalue + 'st';
      }
      if (FirstCheck == 2 && SecondCheck != 12) {
        return covertvalue + 'nd';
      }
      if (FirstCheck == 3 && SecondCheck != 13) {
        return covertvalue + 'rd';
      }
      return covertvalue + 'th';
    } else {
      return value;
    }
  };
  const addArrayValue = (SourceArray: any, Keyname: string) => {
    const ArrayWithValues = SourceArray.map((ele: any) => {
      return parseFloat(ele[Keyname]);
    });
    let AdditionValue = 0;
    for (let index = 0; index < ArrayWithValues.length; index++) {
      AdditionValue = AdditionValue + ArrayWithValues[index];
    }
    return AdditionValue.toString();
  };
  function formatMoney(Money: any) {
    console.log('Money', Money);

    if (Money !== undefined && typeof Money === 'string') {
      let MoneyFormat = Money.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (MoneyFormat === '0') {
        MoneyFormat = `${MoneyFormat}.00`;
      }
      return MoneyFormat;
    }
  }
  const StorePreviousDropdownValue = () => {
    setsuccessDropdownValues({
      ...successDropdownValues,
      Schedule: scheduleValue,
      Term: termValue,
      Range: rangeValue,
      PromoCode: offerCodeValue,
      OptionalService: optServiceValue,
    });
  };
  const DropdownReset = () => {
    setscheduleValue(successDropdownValues.Schedule);
    settermValue(successDropdownValues.Term);
    setrangeValue(successDropdownValues.Range);
    setoptServiceValue(successDropdownValues.OptionalService);
    setofferCodeValue(successDropdownValues.PromoCode);
    if (successDropdownValues.Schedule == 'WK') {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: true,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
    }
    if (successDropdownValues.Schedule == 'BIWK') {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: true,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
    }
    if (successDropdownValues.Schedule == 'MON') {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: true,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
    } else {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: true,
      });
      setrangeactive(true);
    }
  };
  // const DropdownResetError = () => {
  //   setofferCodeValue(errbackupVal == null ? '' : errbackupVal);
  // };
  const WeeklyScheduleOnClick = (ScheduleName: string) => {
    if (!editDisableMode && scheduleValue != 'WK') {
      console.log('Weekly Schedule selected');
      const TempGetPriceReq: any = priceQuoteReq;
      TempGetPriceReq.schedule = 'WK';
      setscheduleValue('WK');
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: true,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
      settermValue(paymentScheduleValues.weeklyTerm);
      StorePreviousDropdownValue();
      Recalculation(TempGetPriceReq);
    }
  };
  const BiWeeklyScheduleOnClick = (ScheduleName: string) => {
    if (!editDisableMode && scheduleValue != 'BIWK') {
      console.log('BiWeekly Schedule selected');
      const TempGetPriceReq: any = priceQuoteReq;
      TempGetPriceReq.schedule = 'BIWK';
      setscheduleValue('BIWK');
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: true,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
      settermValue(paymentScheduleValues.biweeklyTerm);
      StorePreviousDropdownValue();
      Recalculation(TempGetPriceReq);
    }
  };
  const MonthlyScheduleOnClick = (ScheduleName: string) => {
    if (!editDisableMode && scheduleValue != 'MON') {
      console.log('Monthly Schedule selected');
      const TempGetPriceReq: any = priceQuoteReq;
      TempGetPriceReq.schedule = 'MON';
      setscheduleValue('MON');
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: true,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
      settermValue(paymentScheduleValues.monthlyTerm);
      StorePreviousDropdownValue();
      Recalculation(TempGetPriceReq);
    }
  };
  const SemiMonthlyOnClick = (ScheduleName: string) => {
    if (!editDisableMode && scheduleValue != 'SEMI1-15') {
      console.log('SemiMonthly Schedule selected');
      const TempGetPriceReq: any = priceQuoteReq;
      TempGetPriceReq.schedule = 'SEMI1-15';
      setscheduleValue('SEMI1-15');
      setrangeValue('SEMI1-15');
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: true,
      });
      setrangeactive(true);
      settermValue(paymentScheduleValues.semimonthlyTerm);
      StorePreviousDropdownValue();
      Recalculation(TempGetPriceReq);
    }
  };
  const AgreementSourceOnchange = (value: any) => {
    console.log('AgrSourceOnchange Triggered');
    setagreementSourceValue(value);
  };
  const ScheduleOnchange = (value: any) => {
    console.log('ScheduleOnchange Triggered');
    const ScheduleType = value;
    const TempGetPriceReq: any = priceQuoteReq;
    setscheduleValue(ScheduleType);
    StorePreviousDropdownValue();
    if (ScheduleType == 'WK') {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: true,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
      settermValue(paymentScheduleValues.weeklyTerm);
    }
    if (ScheduleType == 'BIWK') {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: true,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
      settermValue(paymentScheduleValues.biweeklyTerm);
    }
    if (ScheduleType == 'MON') {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: true,
        SemiMonthlySchedule: false,
      });
      setrangeactive(false);
      settermValue(paymentScheduleValues.monthlyTerm);
    }
    if (ScheduleType == 'SEMI1-15') {
      setselectedSchedule({
        ...selectedSchedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: true,
      });
      setrangeactive(true);
      setrangeValue('SEMI1-15');
      settermValue(paymentScheduleValues.semimonthlyTerm);
    }
    TempGetPriceReq.schedule = ScheduleType;
    Recalculation(TempGetPriceReq);
  };
  const RangeOnchange = (value: any) => {
    console.log('RangeOnchange Triggered');
    const TempGetPriceReq: any = priceQuoteReq;
    TempGetPriceReq.schedule = value;
    setrangeValue(value);
    StorePreviousDropdownValue();
    Recalculation(TempGetPriceReq);
  };
  const OfferCodeOnchange = (value: any) => {
    console.log('OfferCodeOnchange Triggered');
    const TempGetPriceReq: any = priceQuoteReq;
    value != ''
      ? (TempGetPriceReq.promoCode = value)
      : (TempGetPriceReq.promoCode = null);
    setofferCodeValue(value);
    StorePreviousDropdownValue();
    Recalculation(TempGetPriceReq);
  };
  const OptServiceOnchange = (value: any) => {
    const TempGetPriceReq: any = priceQuoteReq;
    StorePreviousDropdownValue();
    if (
      !CLUB_STATUS_ACTIVE_PENDING.includes(ClubStatus) &&
      IsLdwTiedWithClub === true &&
      LDWstatus !== 'A' &&
      value === 'LDWC'
    ) {
      console.log('setAddingBpclubOpen');
      setAddingBpclubOpen(true);
    } else if (
      CLUB_STATUS_ACTIVE_PENDING.includes(ClubStatus) &&
      IsLdwTiedWithClub === true &&
      value === 'NONE'
      // ClubOriginStore == window.sessionStorage.getItem('storeNumber')
    ) {
      console.log('setDecliningLdwOpen');
      setDecliningLdwOpen(true);
    } else {
      if (value == 'LDWC') {
        TempGetPriceReq.isLDWApplied = true;
        TempGetPriceReq.isClubApplied = true;
      } else if (value == 'NONE') {
        TempGetPriceReq.isLDWApplied = false;
        TempGetPriceReq.isClubApplied = false;
      } else if (value == 'LDW') {
        TempGetPriceReq.isLDWApplied = true;
        TempGetPriceReq.isClubApplied = false;
      }

      Recalculation(TempGetPriceReq);
      setoptServiceValue(value);
    }
  };
  const SalesPersonOnchange = (value: any) => {
    setsalesPersonValue(value);
    if (value != '') {
      setnextBtnDisabled(false);
    } else {
      setnextBtnDisabled(true);
    }
  };

  const PreviousBtnClick = () => {
    if (AgreementId && salesLeadId) {
      history.push({
        pathname: `/agreement/customerorder/itemsearch/${customerId}/${AgreementId}?coCustomerId=N&salesLeadId=${salesLeadId}`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (AgreementId == undefined && salesLeadId == undefined) {
      history.push({
        pathname: `/agreement/customerorder/itemsearch/${customerId}?coCustomerId=N`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (!AgreementId) {
      history.push({
        pathname: `/agreement/customerorder/itemsearch/${customerId}`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (AgreementId) {
      history.push({
        pathname: `/agreement/customerorder/itemsearch/${customerId}/${AgreementId}?coCustomerId=N`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };
  const DropdownResetError = () => {
    setofferCodeValue(errbackupVal == null ? '' : errbackupVal);
    setscheduleValue('WK');
    setselectedSchedule({
      ...selectedSchedule,
      WeeklySchedule: true,
      BiWeeklySchedule: false,
      MonthlySchedule: false,
      SemiMonthlySchedule: false,
    });
  };
  const Recalculation = async (RequestObj: any) => {
    console.log('RequestObj', RequestObj);
    try {
      setprocessFeeDisable(true);
      setpageLoading(true);
      seteditDisableMode(true);
      seterrbackupVal(RequestObj?.promoCode);
      const getCOpriceQuoteResponse = await getCOpriceQuote(RequestObj);
      setpageLoading(false);
      seteditDisableMode(false);
      if (getCOpriceQuoteResponse.status == 200) {
        setprocessFeeDisable(false);
        setpriceQuoteRes(getCOpriceQuoteResponse.data);
        setpriceQuoteReq(RequestObj);
        const getCOpriceQuoteData = getCOpriceQuoteResponse.data;
        GetPriceQuoteValueBind(getCOpriceQuoteData);
      } else if (getCOpriceQuoteResponse.status == 400) {
        setprocessFeeDisable(false);
        const ErrorMessage = getCOpriceQuoteResponse.data.errors[0].error;
        setapiFailFirstMessage(ErrorMessage);
        setapiFailSecondMessage('Please update and try again');
        setapiFailPopupOpen(true);
        DropdownResetError();
      } else {
        DropdownReset();
        setprocessFeeDisable(false);
        setpageLoading(false);
        setsomethingWentWrongOpen(true);
      }
    } catch {
      console.log('Im in Recalculation catch');
      setpageLoading(false);
      seteditDisableMode(false);
      setsomethingWentWrongOpen(true);
    }
  };
  // const NextBtnClick = () => {
  //   console.log('next btn clicked');
  //   if (
  //     PopupCompleteStatus.AgreementCreated === true &&
  //     PopupCompleteStatus.ConfirmDueDateComplete === true
  //   ) {
  //     setcustomerSignatureEnabled(true);
  //   } else if (
  //     PopupCompleteStatus.AgreementCreated === false &&
  //     PopupCompleteStatus.ConfirmDueDateComplete === false
  //   ) {
  //     setsecondFactorEnabled(true);
  //   } else if (PopupCompleteStatus.CustomerSignComplete === true) {
  //     setpaymentPopupOpen(true);
  //   } else if (PopupCompleteStatus.InitialPaymentComplete === true) {
  //     history.push({
  //       pathname: `/agreement/customerorder/postatus/${customerId}/${AgreementId}`,
  //     });
  //     const navEvent = new PopStateEvent('popstate');
  //     window.dispatchEvent(navEvent);
  //   }
  // };
  // const NextBtnClick = () => {
  //   console.log('next btn clicked', PopupCompleteStatus);
  //   if (PopupCompleteStatus.InitialPaymentComplete === true) {
  //     history.push(
  //       `/agreement/customerorder/postatus/${customerId}/${
  //         AgreementId == undefined ? agreementId : AgreementId
  //       }`
  //     );
  //     const navEvent = new PopStateEvent('popstate');
  //     window.dispatchEvent(navEvent);
  //   } else if (PopupCompleteStatus.CustomerSignComplete === true) {
  //     setpaymentPopupOpen(true);
  //   } else if (
  //     PopupCompleteStatus.AgreementCreated === false &&
  //     PopupCompleteStatus.ConfirmDueDateComplete === false
  //   ) {
  //     setsecondFactorEnabled(true);
  //   } else if (
  //     PopupCompleteStatus.AgreementCreated === true &&
  //     PopupCompleteStatus.ConfirmDueDateComplete === true
  //   ) {
  //     setcustomerSignatureEnabled(true);
  //   }
  // };


  // const NextBtnClick = () => {
  //   // setTbIframePopup(true)
  //   tbTwoFactorCompletedFn()
  // }
  const NextBtnClick = () => {
    debugger
    if ((isTireBuyerFlow == '1' || getAgreementInfoRes?.installerInformation) && featureFlagDetails?.TireBuyer == '1') {
      tbCustomerOrderNextClick()
      return
    }
    else if (
      PopupCompleteStatus.PrelimpaymentPaymentComplete === true ||
      PopupCompleteStatus.AgreementStatus == 'Preliminary Refunded'
    ) {
      history.push(
        `/agreement/customerorder/postatus/${customerId}/${AgreementId == undefined ? agreementId : AgreementId
        }`
      );
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (PopupCompleteStatus.InitialPaymentComplete === true) {
      history.push(
        `/agreement/customerorder/delivery/${customerId}/${AgreementId == undefined ? agreementId : AgreementId
        }`
      );
    } else if (
      PopupCompleteStatus.PrelimpaymentPaymentComplete === false &&
      PopupCompleteStatus.CustomerSignComplete === true
    ) {
      setpaymentPopupOpen(true);
    } else if (
      PopupCompleteStatus.CustomerSignComplete === false &&
      PopupCompleteStatus.PrelimpaymentPaymentComplete === false &&
      PopupCompleteStatus.AgreementCreated === false
    ) {
      setsecondFactorEnabled(true);
    } else if (
      PopupCompleteStatus.CustomerSignComplete === false &&
      PopupCompleteStatus.AgreementCreated === true
    ) {
      setcustomerSignatureEnabled(true);
    }
  };
  const tbCustomerOrderNextClick = async () => {
    debugger
    if (getAgreementInfoRes?.agreementWorkflow?.isAgreementSigned != '1' && (getAgreementInfoRes?.agreementWorkflow?.isPreliminaryPaymentPaid == '1' || getAgreementInfoRes?.agreementWorkflow?.isPreliminaryPaymentPaid == '0')) {
      setcustomerSignatureEnabled(true);
    }
    else if (getAgreementInfoRes?.agreementWorkflow?.isAgreementSigned == '1' && getAgreementInfoRes?.agreementWorkflow?.isPreliminaryPaymentPaid != '1') {
      setpaymentPopupOpen(true)

    }
    else if (getAgreementInfoRes?.agreementWorkflow?.isAgreementSigned == '1' && getAgreementInfoRes?.agreementWorkflow?.isPreliminaryPaymentPaid == '1') {
      history.push(
        `/agreement/customerorder/postatus/${customerId}/${AgreementId == undefined ? agreementId : AgreementId
        }`
      );
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
    else if (normalFlowCustomerSign) {
      setpaymentPopupOpen(true)

    }
    else if (
      tbCustomerSignCancel
    ) {
      setcustomerSignatureEnabled(true)
    }
    else {
      settbSecondFactor(true)

    }

  }
  const PreliminaryPaymentCloseClick = () => {
    console.log('Close click triggered');
    setpaymentPopupOpen(false);
  };
  const PreliminaryPaymentNoBtnclick = () => {
    console.log('No click triggered');
    setpaymentPopupOpen(false);
  };
  const PreliminaryPaymentYesclick = () => {
    console.log('yes click triggered');
    setpaymentPopupOpen(false);
    history.push(
      `/payment1/customerorder/preliminary/${customerId}/${AgreementId == undefined ? agreementId : AgreementId
      }`
    );
  };
  const TwoFactorCancelFn = () => {
    setsecondFactorEnabled(false);
  };
  function getActivePhoneNumber(phoneNumbers: any) {
    for (const phoneNumber of phoneNumbers) {
      switch (phoneNumber.phoneType) {
        case 'CELL':
          return phoneNumber.PhoneNumber;
        case 'HOME':
          return phoneNumber.PhoneNumber;
        case 'REF':
          return phoneNumber.PhoneNumber;
        case 'WORK':
          return phoneNumber.PhoneNumber;
        default:
          return phoneNumber.PhoneNumber

      }
    }
    return undefined;
  }
  const TwoFactorCompleteFn = async (value: any) => {
    if (value) {
      console.log('optServiceValuehbb', optServiceValue);

      setsecondFactorEnabled(false);
      setcreateAgrProcessingPopup(true);
      const createAgreementReqTemp: any = createAgreementReq;
      createAgreementReqTemp.sacTotal = sacAmount;
      createAgreementReqTemp.schedule = scheduleValue;
      createAgreementReqTemp.agreementSource = agreementSourceValue;
      createAgreementReqTemp.salesPersonId = salesPersonValue;
      createAgreementReqTemp.items = [
        {
          rmsItemNumber: searchResult[0].rmsItemNumber,
          warehouseId: searchResult[0].wareHouseId,
          packageId: null,
          rmsItemQuantity: '1',
          weeklyRate: searchResult[0].weeklyRate,
          weeklyTerm: searchResult[0].weeklyTerm,
          cashPrice: searchResult[0].cashPrice,
          unitCost:
            searchResult[0].availability?.unitCost
              ? String(searchResult[0]?.availability?.unitCost)
              : null,
          offerId:
            searchResult[0]?.availability?.offerId == ''
              ? null
              : searchResult[0]?.availability?.offerId,
        },
      ];
      createAgreementReqTemp.promoCode =
        offerCodeValue != '' ? offerCodeValue : null;
      createAgreementReqTemp.isLDWApplied =
        optServiceValue == 'LDW' || optServiceValue == 'LDWC'
          ? 'true'
          : 'false';
      createAgreementReqTemp.isClubApplied =
        optServiceValue == 'LDWC' || optServiceValue == 'BPC'
          ? 'true'
          : 'false';
      createAgreementReqTemp.agreementRate =
        priceQuoteRes.paymentSchedule.weeklyRate;
      createAgreementReqTemp.agreementTerm =
        priceQuoteRes.paymentSchedule.weeklyTerm;
      const CreateAgreementResponse = await coCreateAgreement(
        createAgreementReqTemp
      );
      if (CreateAgreementResponse.status == 200) {
        //setcustomerSignatureEnabled(true);
        setprocessFeeDisable(true);
        setPopupCompleteStatus({
          ...PopupCompleteStatus,
          CustomerSignComplete: false,
          AgreementCreated: true,
        });
        setAgreementId(CreateAgreementResponse.data.agreementId.toString());
        setcreateAgrProcessingPopup(false);
        const AgreementCustomers =
          CreateAgreementResponse.data.customerInformation;
        const Customerdata = AgreementCustomers.filter(
          (obj) => obj.priority == 1
        );
        const CustomerName =
          Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
        if (AgreementCustomers.length === 1) {
          const AGreementDocObj: any = GenerateDocReq;
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
          ];
          console.log('PayloadForm');
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
          setAgrCustomerObj(CustomerObj);
          setcustomerSignatureEnabled(true);
        } else {
          const AGreementDocObj: any = GenerateDocReq;
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority == 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber: CoCustomer[0]?.phoneNumbers ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) : CoCustomer[0]?.phoneNumber : CoCustomer[0]?.phoneNumber,
              emailId: CoCustomer[0].emailAddress,
              priority: 2,
            },
          ];
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
          setAgrCustomerObj(CustomerObj);
          setcustomerSignatureEnabled(true);
        }
        const AgreementDocReq: any = GenerateDocReq;
        AgreementDocReq.identifier = parseInt(
          CreateAgreementResponse.data.agreementId
        );
        AgreementDocReq.identifierType = 'AGR';
        AgreementDocReq.signatureType = 1;
        AgreementDocReq.isRemote = false;
        AgreementDocReq.manualSignatureReasonId = null;
        if (AgreementCustomers.length === 1) {
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        } else {
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority == 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber:
                CoCustomer[0].phoneNumber !== null
                  ? CoCustomer[0].phoneNumber
                  : '1111111111',
              emailId:
                CoCustomer[0].emailAddress !== null
                  ? CoCustomer[0].emailAddress
                  : 'test@example.com',
              priority: 2,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        }
        setpreviousBtnDisabled(true);
        const GenerateAgreementDocRes = await GenerateAgreementDoc(
          AgreementDocReq
        );
        console.log(
          'First Generate Agreement Call response',
          GenerateAgreementDocRes
        );
        setpreviousBtnDisabled(false);
        setdocumentGenerateCompleted(true);
        seteditDisableMode(true);
      } else if (CreateAgreementResponse.status == 400) {
        setprocessFeeDisable(false);
        setcreateAgrProcessingPopup(false);
        const ErrorMessage = CreateAgreementResponse.data.errors[0].error;
        setapiFailFirstMessage(ErrorMessage);
        setapiFailSecondMessage('Please try again');
        setapiFailPopupOpen(true);
      } else {
        setprocessFeeDisable(false);
        setcreateAgrProcessingPopup(false);
        setsomethingWentWrongOpen(true);
      }
    }
  };
  const customerSignatureCompletedFn = () => {
    setpaymentPopupOpen(true);
    setnormalFlowCustomerSign(true)
    setcustomerSignatureEnabled(false);
    setPopupCompleteStatus({
      ...PopupCompleteStatus,
      CustomerSignComplete: true,
    });
  };
  const customerSignatureCanceledFn = () => {
    setcustomerSignatureEnabled(false);
    settbCustomerSignCancel(true)
  };
  // eslint-disable-next-line sonarjs/no-identical-functions
  const emailcompletedFn = () => {
    setpaymentPopupOpen(true);
    setcustomerSignatureEnabled(false);
    setPopupCompleteStatus({
      ...PopupCompleteStatus,
      CustomerSignComplete: true,
    });
  };
  const CreateAgreementProcessingPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <Typography className={classes.formLabel}>
            We are creating the agreement for you!
          </Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const ApiFailPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            {apiFailFirstMessage}
          </Typography>
          <Typography className={classes.formLabel}>
            {' '}
            {apiFailSecondMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setapiFailPopupOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const SomethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setsomethingWentWrongOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const PreliminaryPaymentPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.mb4}>
            Do you want to take preliminary payment?
          </Typography>
        </Grid>
        {/* <Typography style={{ textAlign: 'center', paddingBottom: '14px' }}>
          Note: Please proceed preliminary payment in SIMS
        </Typography> */}
        <Grid className={`${classes.pb4} ${classes.textCenter}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => PreliminaryPaymentNoBtnclick()}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => PreliminaryPaymentYesclick()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  const OtherAmountOnclick = () => {
    setSecondFactorOpen(true);
  };
  const OtherAmtOnblurClick = (e: any) => {
    try {
      const InputTxt = e.target.value;
      let InputTxtArr = InputTxt.split('');
      if (InputTxtArr.includes(',') === true) {
        InputTxtArr = InputTxtArr.filter((element: any) => element != ',');
        let TempString = '';
        for (let i = 0; i <= InputTxtArr.length - 1; i++) {
          TempString = TempString + InputTxtArr[i];
        }
        const FloatInputTxt = parseFloat(TempString);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setOtherAmt(ResultInputTxt);
      } else if (InputTxt === '.' || InputTxt === '') {
        setOtherAmt('0.00');
      } else {
        const FloatInputTxt = parseFloat(InputTxt);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setOtherAmt(ResultInputTxt);
      }
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnBlurClick = (e: any) => {
    try {
      const InputTxt = e.target.value;
      let InputTxtArr = InputTxt.split('');
      if (InputTxtArr.includes(',') === true) {
        InputTxtArr = InputTxtArr.filter((element: any) => element !== ',');
        let TempString = '';
        for (let i = 0; i <= InputTxtArr.length - 1; i++) {
          TempString = TempString + InputTxtArr[i];
        }
        const FloatInputTxt = parseFloat(TempString);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setOtherAmt(ResultInputTxt);
      } else if (InputTxt === '.' || InputTxt === '') {
        // setIntPayment({ ...IntPayment, OtherAmt: '0.00' });
        setOtherAmt('0.00');
      } else {
        const FloatInputTxt = parseFloat(InputTxt);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setOtherAmt(ResultInputTxt);
      }
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnClick = (e: any) => {
    try {
      const InputTxt = e.target;
      InputTxt.select();
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnchange = (value: any) => {
    try {
      const TempTxt = parseFloat(value);
      if (TempTxt <= 999.0) {
        setOtherAmt(value);
      }
      if (value == undefined || value === '') {
        setOtherAmt('');
      }
    } catch {
      throw Error;
    }
  };
  const OverRideAmoutClick = () => {
    try {
      // const InputProcessingFeeAmt = parseFloat(processingFee);
      // const ProcessingFeeScale = parseFloat(ProcessingFeeLimit);
      const Recalculatereqobj: any = RecalculateReq;
      if (Number(processingFee) >= Number(OtherAmt)) {
        // const TempReq: any = FeeArrWOProFee;
        setProcessingFeeErrorLbl(false);
        setProcessingFeePopupOpen(false);
        const Obj = {
          feeType: 'PROC',
          feeAmount: processingFee,
        };
        // if (TempReq.filter((e) => e.feeType === 'PROC').length > 0) {
        //   const indexOfDuplicate = TempReq.findIndex(
        //     (arr) => arr.feeType === Obj.feeType
        //   );
        //   TempReq[indexOfDuplicate] = Obj;
        // } else {
        //   TempReq.push(Obj);
        // }
        // console.log('Regenerated fee arr', TempReq);
        //props.OtherAmtChanged(TempReq);
        Recalculatereqobj.processingFee = processingFee;
        //props.RecalculateClicked(Recalculatereqobj);
        setRecalculateReq(Recalculatereqobj);
      } else {
        setProcessingFeeErrorLbl(true);
      }
    } catch {
      throw Error;
    }
  };
  const ProcessingFeePopup = () => {
    return (
      <div
        id="ProcessingFee"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid container className={`${classes.mb2} ${classes.mt2}`}>
          <Grid item md={6} className={classes.floatLeft}>
            <Typography
              variant="subtitle1"
              className={paymentCostClassName.cardLabel}
            >
              Processing Fee Amount
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Grid className={classes.floatRight}>
              <Typography
                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
              >
                $
              </Typography>
              <CurrencyInput
                id="input-example"
                name=""
                style={{ zIndex: 0, textAlign: 'right' }}
                // className={
                //   OtherDisable
                //     ? `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`
                //     : `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput}`
                // }
                className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput}`}
                decimalsLimit={2}
                data-testid="ProcessingFeeTxtBoxTest"
                value={OtherAmt}
                onBlur={(e) => ProcessingFeeOnBlurClick(e)}
                onClick={(e) => ProcessingFeeOnClick(e)}
                onValueChange={(value) => ProcessingFeeOnchange(value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <label
          style={{
            color: 'red',
            fontSize: 'small',
            marginLeft: '15%',

            visibility: ProcessingFeeErrorLbl === false ? 'hidden' : 'visible',
          }}
          data-testid="OverRideErrorMsgTest"
        >
          Please enter a valid processing fee amount less than $
          {`${processingFee}`}
        </label>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => OverRideAmoutClick()}
          >
            Override Amount
          </RACButton>
        </Grid>
      </div>
    );
  };
  const setTwoFactorCompleted = (value: any) => {
    if (value) {
      setProcessingFeePopupOpen(true);
    }
  };
  const setTwoFactorCancelClick = () => {
    setSecondFactorOpen(false);
  };
  const DecliningLdwOkclick = () => {
    const Recalculatereqobj: any = priceQuoteReq;
    setDecliningLdwOpen(false);
    setoptServiceValue('NONE');
    //setDropdownChanged({ ...DropdownChanged, Optservices: true });
    // Recalculatereqobj.optionalservice = {
    //   isLDWapplied: false,
    //   isClubApplied: false,
    // };
    Recalculatereqobj.isLDWApplied = false;
    Recalculatereqobj.isClubApplied = false;
    Recalculation(Recalculatereqobj);
  };
  const DecliningLdwNoclick = () => {
    setDecliningLdwOpen(false);
  };
  const AddingBenefitsPlusClubOkClick = () => {
    const Recalculatereqobj: any = priceQuoteReq;
    setAddingBpclubOpen(false);
    //setDropdownChanged({ ...DropdownChanged, Optservices: true });
    setoptServiceValue('LDWC');
    Recalculatereqobj.isLDWApplied = true;
    Recalculatereqobj.isClubApplied = true;
    Recalculation(Recalculatereqobj);
  };
  const AddingBenefitsPlusClubNoClick = () => {
    setAddingBpclubOpen(false);
  };
  const AddingBenefitsPlusClubPopup = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            By choosing the option ‘LDW/Club’, you will be adding Benefit Plus
            club. LDW fee will be added on your current agreement and any
            existing agreements which do not have LDW.
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Do you want to Continue?
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => AddingBenefitsPlusClubNoClick()}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => AddingBenefitsPlusClubOkClick()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const DecliningLdwPopup = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Declining LDW on this agreement will also terminate your Benefits
            Plus Coverage. You would not be able to reactivate club to cover any
            previous Paid In Full merchandise.
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Do you still wish to terminate?
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => DecliningLdwNoclick()}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => DecliningLdwOkclick()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const DecliningLdwCloseClick = () => {
    setDecliningLdwOpen(false);
  };
  const AddingBpclubCloseClick = () => {
    setAddingBpclubOpen(false);
  };

  const tbTwoFactorCompletedFn = async (value?: any) => {
    debugger
    setpageLoader(true)
    const payloadarray: any = [];

    for (const value of addedItems) {
      const productsArray = {
        productNumber: value.modelNumber,
        fitmentId: salesleadFitemnetid ? salesleadFitemnetid : modelNumberArray[0].fitmentId,
        qty: value.quantity
      }
      payloadarray.push(productsArray)
    }
    // const payload = {
    //   supplierNumber: "361875",
    //   zipCode: "12345",
    //   successRedirectUrl: "https://www.successurl.com",
    //   cancelRedirectUrl: "https://www.cancelurl.com",
    //   products: [
    //     {
    //       productNumber: "TV153000774",
    //       fitmentId: "95628",
    //       qty: 4,
    //     }
    //   ]
    // }
    const payload = {
      supplierNumber: addedItems[0].supplierNumber,
      zipCode: customerInformation.addresses[0].postalCode.length > 5 ? customerInformation.addresses[0].postalCode.substring(0, 5) : customerInformation.addresses[0].postalCode,
      successRedirectUrl: "https://www.successurl.com",
      cancelRedirectUrl: "https://www.cancelurl.com",
      products: payloadarray
    }
    const tireCreateCartRes = await tireCreateCart(payload)
    setpageLoader(false)
    if (tireCreateCartRes.status == 200) {
      setIframeUrl(tireCreateCartRes?.data?.url)
      setTbIframePopup(true)
    }
    else if (tireCreateCartRes.status == 400) {
      setapiFailPopupOpen(true)
    }
    else {
      setsomethingWentWrongOpen(true)
    }
  }
  const tbTwoFactorCancelleddFn = async () => {
    settbSecondFactor(false)
  }


  function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const docuSignOnload = () => {
    debugger
    window.addEventListener('message', (event) => {
      debugger
      var installerDetailsForm = document.getElementById('IframeID') as HTMLFormElement | null;
      if (event.data) {
        debugger;
        console.log("event.data", event.data)
        var selectedInstaller = JSON.parse(event.data).selectedInstaller;
        var selectedAppointment = JSON.parse(event.data).selectedAppointment;
        if (event.data) {
          console.log("hiiii12")
          const installerAddress = {
            addressLine1: selectedInstaller.address.line1,
            addressLine2: selectedInstaller.address.line2,
            city: selectedInstaller.address.city,
            state: selectedInstaller.address.state,
            postalCode: selectedInstaller.address.zipCode
          }
          setInstallerInfo({ ...installerInfo, installerName: selectedInstaller.name, emailAddress: selectedInstaller.address.email, phoneNumber: selectedInstaller.address.phone, installationDate: selectedAppointment.year + '-' + moment().month(selectedAppointment.month).format('MM') + '-' + selectedAppointment.date, installationTime: selectedAppointment.period, installerAddress: installerAddress })

        }

      }
      else {
        console.log('Installation Not scheduled');
      }
      setTbIframePopup(false)
      if (event.data) {
        setinstallationSuccessfull(true)
      }
    });

  }
  const tbIframePopupFn = () => {
    return (
      <Grid className={`${classes.w100} ${classes.floatLeft}`}>
        <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
          {iframeUrl === '' ? (
            <Grid className={classes.iframeClass}>
              <CircularProgress
                style={{ marginTop: '200px', marginLeft: '575px' }}
                size={30}
              />
            </Grid>
          ) :
            <Grid>
              {salesinstallerName ? <>
                <Grid >
                  <Grid style={{ float: 'left' }}>
                    <Typography style={{ fontFamily: 'OpenSans-bold', fontSize: '20px', marginBottom: '23px', display: 'inline-block' }}>Installtion Details chosen by Customer
                      <Infoicon

                        title={"Installer details for the lead will be shown here"}

                        onMouseOver={() => {

                          "Transfer";

                        }}
                      >
                      </Infoicon>
                      {/* 
                      <Infosus

                        title={"Transfer"}

                        onMouseOver={() => {

                          "Transfer";

                        }}

                      ></Infosus> */}
                    </Typography>
                  </Grid>
                  {/* <Infoicon>


                    <RACInfoIcon iconClassname={classes.ReturnInfoIcon}
                      placement='top-start'
                      infoTitle='Installer details for the lead will be shown here'
                    />

                  </Infoicon> */}
                  {/* <span className={classes.tooltip}><Infoicon></Infoicon></span><span className={classes.tooltiptext}></span> */}
                  <Grid container spacing={2} style={{ marginBottom: '23px', marginLeft: '0px' }}>
                    <Grid md={3}>
                      <Typography style={{ fontFamily: 'OpenSans-bold', fontSize: '14px' }}>Installation Name :{salesinstallerName ? salesinstallerName : '-'}</Typography>
                    </Grid>
                    <Grid md={2}>
                      <Typography style={{ fontFamily: 'OpenSans-bold', fontSize: '14px' }}>Date :{salesinstalldate ? salesinstalldate : '-'}</Typography>
                    </Grid>
                    <Grid md={2}>
                      <Typography style={{ fontFamily: 'OpenSans-bold', fontSize: '14px' }}>Tiem Slot:{salesTimeSlot ? salesTimeSlot : '-'}</Typography>
                    </Grid>

                  </Grid>
                  <Typography style={{ fontFamily: 'OpenSans-bold', fontSize: '18px', marginBottom: '23px' }}> Schedule Installation</Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px',
                      marginTop: 10,
                      marginBottom: 10,
                      backgroundColor: "#E0EAFF"
                    }}
                  >

                    <label
                      htmlFor="flexCheckDefault-6"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'OpenSans-bold',
                        fontSize: 13,
                        color: '#2179FE',
                      }}
                    >
                      Note : Customers have already selected the above installation details in leads. Please confirm the details with the customer and proceed with installation.
                    </label>
                  </div>
                </Grid>
              </> : <Typography style={{ fontFamily: 'OpenSans-bold', fontSize: '20px', marginBottom: '23px' }}>Schedule Installation</Typography>}
              <iframe
                src={iframeUrl}
                className={classes.iframeClass}
                id="IframeID"
                title="Iframe"
                onLoad={() => docuSignOnload()}
              ></iframe>
              <div style={{ float: 'right', padding: '30px' }}>
                <RACButton
                  variant='outlined'
                  color='primary'
                  onClick={() => { setTbIframePopup(false) }}
                >
                  Cancel
                </RACButton>
              </div>


            </Grid>
          }
        </Grid>
      </Grid>
    );
  };
  const TireCOCreateAgreement = async () => {
    setcreateAgrProcessingPopup(true)
    const createAgreementReqTemp: any = createAgreementReq;
    createAgreementReqTemp.sacTotal = sacAmount;
    createAgreementReqTemp.schedule = scheduleValue;
    createAgreementReqTemp.agreementSource = agreementSourceValue;
    createAgreementReqTemp.salesPersonId = salesPersonValue;
    createAgreementReqTemp.items = searchResult.map((obj: any) => {
      return {
        rmsItemNumber: obj.rmsItemNumber,
        warehouseId: obj.wareHouseId,
        packageId: null,
        rmsItemQuantity: String(addedItems.filter((obj1) => obj1.modelNumber == obj.modelNumber)[0].quantity),
        weeklyRate: obj.weeklyRate,
        weeklyTerm: obj.weeklyTerm,
        cashPrice: obj.cashPrice,
        unitCost:
          obj.availability?.unitCost
            ? String(obj?.availability?.unitCost)
            : null,
        offerId:
          obj?.availability?.offerId == ''
            ? null
            : obj?.availability?.offerId,
      }
    })
    // createAgreementReqTemp.items = [
    //   {
    //     rmsItemNumber: searchResult[0].rmsItemNumber,
    //     warehouseId: searchResult[0].wareHouseId,
    //     packageId: null,
    //     rmsItemQuantity: '1',
    //     weeklyRate: searchResult[0].weeklyRate,
    //     weeklyTerm: searchResult[0].weeklyTerm,
    //     cashPrice: searchResult[0].cashPrice,
    //     unitCost:
    //       searchResult[0].availability?.unitCost
    //         ? String(searchResult[0]?.availability?.unitCost)
    //         : null,
    //     offerId:
    //       searchResult[0]?.availability?.offerId == ''
    //         ? null
    //         : searchResult[0]?.availability?.offerId,
    //   },
    // ];
    createAgreementReqTemp.promoCode =
      offerCodeValue != '' ? offerCodeValue : null;
    createAgreementReqTemp.isLDWApplied =
      optServiceValue == 'LDW' || optServiceValue == 'LDWC'
        ? 'true'
        : 'false';
    createAgreementReqTemp.isClubApplied =
      optServiceValue == 'LDWC' || optServiceValue == 'BPC'
        ? 'true'
        : 'false';
    createAgreementReqTemp.agreementRate =
      priceQuoteRes.paymentSchedule.weeklyRate;
    createAgreementReqTemp.agreementTerm =
      priceQuoteRes.paymentSchedule.weeklyTerm;
    debugger;

    const payload = { ...createAgreementReqTemp, installerInfo, vehicleInfo: vehicleInfo }
    const tireCOCreateAgreementRes = await tireCOCreateAgreement(payload)
    if (tireCOCreateAgreementRes.status == 200) {
      //setcustomerSignatureEnabled(true);
      setprocessFeeDisable(true);
      setPopupCompleteStatus({
        ...PopupCompleteStatus,
        CustomerSignComplete: false,
        AgreementCreated: true,
      });
      setAgreementId(tireCOCreateAgreementRes.data.agreementId.toString());
      setcreateAgrProcessingPopup(false);
      const AgreementCustomers =
        tireCOCreateAgreementRes.data.customerInformation;
      const Customerdata = AgreementCustomers.filter(
        (obj) => obj.priority == 1
      );
      const CustomerName =
        Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
      if (AgreementCustomers.length === 1) {
        const AGreementDocObj: any = GenerateDocReq;
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber: Customerdata[0].phoneNumber,
            emailId: Customerdata[0].emailAddress,
            priority: 1,
          },
        ];
        console.log('PayloadForm');
        AGreementDocObj.signers = CustomerObj;
        setGenerateDocReq(AGreementDocObj);
        setAgrCustomerObj(CustomerObj);
        setcustomerSignatureEnabled(true);
      } else {
        const AGreementDocObj: any = GenerateDocReq;
        const CoCustomer = AgreementCustomers.filter(
          (obj) => obj.priority == 2
        );
        const CoCustomerName =
          CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber: Customerdata[0].phoneNumber,
            emailId: Customerdata[0].emailAddress,
            priority: 1,
          },
          {
            customerId: CoCustomer[0].customerId.toString(),
            customerName: CoCustomerName,
            phoneNumber: CoCustomer[0].phoneNumber,
            emailId: CoCustomer[0].emailAddress,
            priority: 2,
          },
        ];
        AGreementDocObj.signers = CustomerObj;
        setGenerateDocReq(AGreementDocObj);
        setAgrCustomerObj(CustomerObj);
        setcustomerSignatureEnabled(true);
      }
      const AgreementDocReq: any = GenerateDocReq;
      AgreementDocReq.identifier = parseInt(
        tireCOCreateAgreementRes.data.agreementId
      );
      AgreementDocReq.identifierType = 'AGR';
      AgreementDocReq.signatureType = 1;
      AgreementDocReq.isRemote = false;
      AgreementDocReq.manualSignatureReasonId = null;
      if (AgreementCustomers.length === 1) {
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber:
              Customerdata[0].phoneNumber !== null
                ? Customerdata[0].phoneNumber
                : '1111111111',
            emailId:
              Customerdata[0].emailAddress !== null
                ? Customerdata[0].emailAddress
                : 'test@example.com',
            priority: 1,
          },
        ];
        AgreementDocReq.signers = CustomerObj;
      } else {
        const CoCustomer = AgreementCustomers.filter(
          (obj) => obj.priority == 2
        );
        const CoCustomerName =
          CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber:
              Customerdata[0].phoneNumber !== null
                ? Customerdata[0].phoneNumber
                : '1111111111',
            emailId:
              Customerdata[0].emailAddress !== null
                ? Customerdata[0].emailAddress
                : 'test@example.com',
            priority: 1,
          },
          {
            customerId: CoCustomer[0].customerId.toString(),
            customerName: CoCustomerName,
            phoneNumber:
              CoCustomer[0].phoneNumber !== null
                ? CoCustomer[0].phoneNumber
                : '1111111111',
            emailId:
              CoCustomer[0].emailAddress !== null
                ? CoCustomer[0].emailAddress
                : 'test@example.com',
            priority: 2,
          },
        ];
        AgreementDocReq.signers = CustomerObj;
      }
      setpreviousBtnDisabled(true);
      const GenerateAgreementDocRes = await GenerateAgreementDoc(
        AgreementDocReq
      );
      console.log(
        'First Generate Agreement Call response',
        GenerateAgreementDocRes
      );
      setpreviousBtnDisabled(false);
      setdocumentGenerateCompleted(true);
      seteditDisableMode(true);
    } else if (tireCOCreateAgreementRes.status == 400) {
      setprocessFeeDisable(false);
      setcreateAgrProcessingPopup(false);
      const ErrorMessage = tireCOCreateAgreementRes.data.errors[0].error;
      setapiFailFirstMessage(ErrorMessage);
      setapiFailSecondMessage('Please try again');
      setapiFailPopupOpen(true);
    } else {
      setprocessFeeDisable(false);
      setcreateAgrProcessingPopup(false);
      setsomethingWentWrongOpen(true);
    }
  }
  const installationSuccessfullPopup = () => {
    return (
      <>
        <div id="testimage" className={classes.agrTranferSuccessImg}>
          <Successicon></Successicon>
        </div>
        <Typography style={{ "textAlign": "center", "fontSize": "17px", fontFamily: 'OpenSans-bold' }}>Installation scheduled successfully</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '20px',
            marginLeft: '170px',
            padding: '12px 30px',
          }}
          onClick={() => {
            setinstallationSuccessfull(false)
            TireCOCreateAgreement()
          }}
        >Ok</RACButton>
      </>
    )
  }

  return (
    <Grid>
      {pageLoader ?
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid> : null}
      {secondFactorEnabled === true ? (
        <SecondFactor
          setTwoFactorCancelClick={() => TwoFactorCancelFn()}
          setTwoFactorCompleted={(value) => TwoFactorCompleteFn(value)}
          moduleName={'Customer Order Agmt Creation'}
          currentRole={currentRole}
        />
      ) : null}
      {SecondFactorOpen === true ? (
        <SecondFactor
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          moduleName={'Edit SAC Fee'}
          currentRole={currentRole}
        />
      ) : null}

      {tbSecondFactor === true ? (
        <SecondFactor
          setTwoFactorCancelClick={() => tbTwoFactorCancelleddFn()}
          setTwoFactorCompleted={(value) => tbTwoFactorCompletedFn(value)}
          moduleName={'Customer Order Agmt Creation'}
          currentRole={currentRole}
        />) : null}
      {customerSignatureEnabled ? (
        <CustomerSignature
          customerSignUsingField="agreementDetails"
          customerSignUsingModule="customerOrder"
          identifier={parseInt(AgreementId)}
          customersArr={AgrCustomerObj}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={[]}
          customerSignatureCompletedFn={() => customerSignatureCompletedFn()}
          customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
          emailcompletedFn={() => emailcompletedFn()}
          documentGenerateCompleted={documentGenerateCompleted}
        />
      ) : null}
      {somethingWentWrong ? (
        <div
          className="Norecords"
          style={{ marginTop: '20%', marginLeft: '40%' }}
        >
          <Alerticon></Alerticon>
          Something went wrong!
        </div>
      ) : (
        <Grid>
          <RACModalCard
            isOpen={tbIframePopup}
            closeIcon={true}
            // title='Schedule Installation'
            onClose={() => setTbIframePopup(false)}
            maxWidth='lg'
            children={tbIframePopupFn()}
            className={`${classes.customModal}`}
            borderRadius={'20px !important'}
          />
          <RACModalCard
            isOpen={installationSuccessfull}
            closeIcon={true}
            onClose={() => {
              setinstallationSuccessfull(false);
              TireCOCreateAgreement()

            }}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {installationSuccessfullPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={apiFailPopupOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {ApiFailPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={AddingBpclubOpen}
            closeIcon={true}
            onClose={() => AddingBpclubCloseClick()}
            maxWidth="xs"
            borderRadius={'25px !important'}
          >
            {AddingBenefitsPlusClubPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={DecliningLdwOpen}
            onClose={() => DecliningLdwCloseClick()}
            closeIcon={true}
            maxWidth="xs"
            borderRadius={'25px !important'}
          >
            {DecliningLdwPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={ProcessingFeePopupOpen}
            closeIcon={false}
            maxWidth="sm"
            borderRadius={'25px !important'}
          >
            {ProcessingFeePopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={somethingWentWrongOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {SomethingWentWrongPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={createAgrProcessingPopup}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {' '}
            {CreateAgreementProcessingPopup()}
          </RACModalCard>

          <RACModalCard
            isOpen={paymentPopupOpen}
            closeIcon={true}
            onClose={() => PreliminaryPaymentCloseClick()}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {PreliminaryPaymentPopup()}
          </RACModalCard>
          <Grid className={classes.px2}>
            <Grid container className={classes.mt2}>
              <Typography
                variant="h6"
                className={`${classes.title} ${classes.mb1}`}
              >
                Payment Schedule
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <PaymentScheduleCard
                Scheduloading={scheduleLoading}
                ScheduleValue={paymentScheduleValues.weeklyRate}
                ScheduleName="Weekly"
                TermValue={paymentScheduleValues.weeklyTerm}
                ScheduleSelected={selectedSchedule.WeeklySchedule}
                ScheduleClickFn={(ScheduleName) =>
                  WeeklyScheduleOnClick(ScheduleName)
                }
                EditDisableMode={editDisableMode}
              />
              <PaymentScheduleCard
                Scheduloading={scheduleLoading}
                ScheduleValue={paymentScheduleValues.biweeklyRate}
                ScheduleName="Bi-Weekly"
                TermValue={paymentScheduleValues.biweeklyTerm}
                ScheduleSelected={selectedSchedule.BiWeeklySchedule}
                ScheduleClickFn={(ScheduleName) =>
                  BiWeeklyScheduleOnClick(ScheduleName)
                }
                EditDisableMode={editDisableMode}
              />
              <PaymentScheduleCard
                Scheduloading={scheduleLoading}
                ScheduleValue={paymentScheduleValues.semimonthlyRate}
                ScheduleName="Semi-Monthly"
                TermValue={paymentScheduleValues.semimonthlyTerm}
                ScheduleSelected={selectedSchedule.SemiMonthlySchedule}
                ScheduleClickFn={(ScheduleName) =>
                  SemiMonthlyOnClick(ScheduleName)
                }
                EditDisableMode={editDisableMode}
              />
              <PaymentScheduleCard
                Scheduloading={scheduleLoading}
                ScheduleValue={paymentScheduleValues.monthlyRate}
                ScheduleName="Monthly"
                TermValue={paymentScheduleValues.monthlyTerm}
                ScheduleSelected={selectedSchedule.MonthlySchedule}
                ScheduleClickFn={(ScheduleName) =>
                  MonthlyScheduleOnClick(ScheduleName)
                }
                EditDisableMode={editDisableMode}
              />
            </Grid>
            <Grid>
              <Grid container className={classes.mt1}>
                <Typography
                  variant="h6"
                  className={`${classes.title} ${classes.mb1}`}
                >
                  General Information
                </Typography>
              </Grid>

              <Grid container>
                <Grid item md={12} className={classes.mb4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container className={classes.mb2}>
                        <Grid
                          item
                          className={agrGeneralInfoClassName.listitemstyles}
                        >
                          <Grid
                            item
                            xs="auto"
                            className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                          >
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColor
                              }
                            >
                              Agreement Type :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                              }
                            >
                              {'Rental'}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs="auto"
                            className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                          >
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColor
                              }
                            >
                              {'  '}Pay Schedule :{' '}
                            </Typography>
                            {paymentScheduleEnabled === true ? (
                              <Typography
                                variant="caption"
                                className={
                                  agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                                }
                              >
                                {'  '}
                                {payschedule.PayschedulePeriod !== 'Daily' &&
                                  payschedule.PayschedulePeriod !== 'daily'
                                  ? payschedule.PayschedulePeriod +
                                  ' on ' +
                                  payschedule.PayscheduleDay
                                  : payschedule.PayschedulePeriod}
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                className={
                                  agrGeneralInfoClassName.paymentGeneralInfoColor
                                }
                              >
                                {'  '}-
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            inputLabel="Agreement Source"
                            options={agreementSourceOptions}
                            defaultValue={agreementSourceValue}
                            // isDisabled={editDisableMode}
                            isDisabled={AgrSourceDisable}
                            onChange={(e: any) =>
                              AgreementSourceOnchange(e.target.value)
                            }
                            loading={dropdownLoading}
                            {...(agreementSourceOptions.length == 1 &&
                              dropdownLoaded && {
                              errorMessage: API_ERROR_MESSAGE,
                            })}
                          />
                        </Grid>

                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            name="Schedule"
                            inputLabel="Schedule"
                            options={scheduleOptions}
                            defaultValue={scheduleValue}
                            isDisabled={editDisableMode}
                            onChange={(e: any) =>
                              ScheduleOnchange(e.target.value)
                            }
                            loading={dropdownLoading}
                            {...(scheduleOptions.length == 1 &&
                              dropdownLoaded && {
                              errorMessage: API_ERROR_MESSAGE,
                            })}
                          />
                          <Typography
                            variant="body1"
                            className={classes.font14}
                          >
                            Term
                            <Typography
                              variant="caption"
                              className={`${classes.bold} ${classes.racTextGreen} ${classes.ms1}`}
                            >
                              {termValue}
                            </Typography>
                          </Typography>
                        </Grid>

                        {rangeactive === true ? (
                          <Grid item sm={4} md={2}>
                            <RACSelect
                              inputLabelClassName={
                                agrGeneralInfoClassName.dropDownLabel
                              }
                              name="Range"
                              inputLabel="Range"
                              options={rangeOptions}
                              defaultValue={rangeValue}
                              isDisabled={editDisableMode}
                              onChange={(e: any) =>
                                RangeOnchange(e.target.value)
                              }
                              loading={dropdownLoading}
                              {...(rangeOptions.length == 1 &&
                                dropdownLoaded && {
                                errorMessage: API_ERROR_MESSAGE,
                              })}
                            />
                          </Grid>
                        ) : null}
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            name=" PromoCode"
                            inputLabel="Promo Code"
                            options={offerCodeOptions}
                            defaultValue={offerCodeValue}
                            isDisabled={editDisableMode}
                            onChange={(e: any) =>
                              OfferCodeOnchange(e.target.value)
                            }
                            loading={dropdownLoading}
                            {...(offerCodeOptions.length == 1 &&
                              dropdownLoaded && {
                              errorMessage: API_ERROR_MESSAGE,
                            })}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            name="Opt Services"
                            inputLabel="Opt Services"
                            options={optServiceOptions}
                            defaultValue={optServiceValue}
                            isDisabled={editDisableMode}
                            onChange={(e: any) =>
                              OptServiceOnchange(e.target.value)
                            }
                            loading={dropdownLoading}
                            {...(optServiceOptions.length == 1 &&
                              dropdownLoaded && {
                              errorMessage: API_ERROR_MESSAGE,
                            })}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            name=" Sales Person"
                            inputLabel="SalesPerson"
                            required={true}
                            options={salesPersonOptions}
                            defaultValue={salesPersonValue}
                            isDisabled={editDisableMode}
                            onChange={(e: any) =>
                              SalesPersonOnchange(e.target.value)
                            }
                            loading={dropdownLoading}
                            {...(optServiceOptions.length == 1 &&
                              dropdownLoaded && {
                              errorMessage: API_ERROR_MESSAGE,
                            })}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.mb6}>
              <Grid item className={paymentCostClassName.paymentWidget}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Typography className={classes.title}>
                      Initial Payment
                    </Typography>

                    <Card className={classes.card}>
                      <CardContent className={classes.p32}>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt2}`}
                        >
                          <Grid item md={6} className={classes.floatLeft}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Processing Fee
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Grid className={classes.floatRight}>
                              <Typography
                                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                              >
                                $
                              </Typography>
                              <CurrencyInput
                                id="input-example"
                                name=""
                                style={{ zIndex: 0, textAlign: 'right' }}
                                className={
                                  processFeeDisable
                                    ? `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`
                                    : `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} `
                                }
                                //disabled={OtherAmt == '0.00' ? true : false}
                                disabled={processFeeDisable}
                                decimalsLimit={2}
                                data-testid="ProcessingFeeTxtBoxTest"
                                // value={processingFee}
                                value={OtherAmt}
                                onBlur={(e) => OtherAmtOnblurClick(e)}
                                onClick={() => OtherAmountOnclick()}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt2}`}
                        >
                          <Grid item md={6} className={classes.floatLeft}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              WL Deposit
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Grid className={classes.floatRight}>
                              <Typography
                                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                              >
                                $
                              </Typography>
                              <CurrencyInput
                                id="input-example"
                                name=""
                                style={{ zIndex: 0, textAlign: 'right' }}
                                className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`}
                                decimalsLimit={2}
                                data-testid="ProcessingFeeTxtBoxTest"
                                value={wlDeposit}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Rate
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.widgetAlign}
                              >
                                $ {formatMoney(initialPaymentAmount.Rate)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.widgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor="#FFFFFF"
                                  highlightColor="#e7e7e7"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              LDW
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ {formatMoney(initialPaymentAmount.LDW)}
                                </Typography>
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.widgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor="#FFFFFF"
                                  highlightColor="#e7e7e7"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Club
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.widgetAlign}
                              >
                                $ {formatMoney(initialPaymentAmount.Club)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.widgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor="#FFFFFF"
                                  highlightColor="#e7e7e7"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Tax
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.widgetAlign}
                              >
                                $ {formatMoney(initialPaymentAmount.Tax)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.widgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor="#FFFFFF"
                                  highlightColor="#e7e7e7"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.pt2} ${paymentCostClassName.payscheduleBorder} ${paymentCostClassName.alignCenter} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={`${classes.font18} ${classes.semiBold}`}
                            >
                              Initial Payment{' '}
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                              >
                                ${' '}
                                {formatMoney(
                                  initialPaymentAmount.InitialPayment
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor="#FFFFFF"
                                  highlightColor="#e7e7e7"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item md={6}>
                    <Typography className={classes.title}>
                      Total Payment/Total Cost
                    </Typography>

                    <Card className={paymentCostClassName.bluecard}>
                      <CardContent className={classes.p4}>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              {/* Initial Payment (
                              {totalPaymentAmount.InitialPaymentTerm}) */}
                              Intial Payment
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                ${' '}
                                {formatMoney(totalPaymentAmount.InitialPayment)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor={RACCOLOR.NORMAL_BLUE}
                                  highlightColor="#FFFFFF"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueCardLabel}
                              >
                                Regular Payments (
                                {totalPaymentAmount.RegularPaymentsTerm})
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueCardLabel}
                              >
                                Regular Payments
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                ${' '}
                                {formatMoney(
                                  totalPaymentAmount.RegularPayments
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor={RACCOLOR.NORMAL_BLUE}
                                  highlightColor="#FFFFFF"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              {/* Final Payment (
                              {totalPaymentAmount.FinalPaymentTerm}) */}
                              Final Payment
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                $ {formatMoney(totalPaymentAmount.FinalPayment)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor={RACCOLOR.NORMAL_BLUE}
                                  highlightColor="#FFFFFF"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={12}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueCardLabel}
                              >
                                {' '}
                                Total Payments (
                                {totalPaymentAmount.TotalPaymentTerm})
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueCardLabel}
                              >
                                {' '}
                                Total Payments
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Card
                          className={`${classes.card} ${classes.p3} ${paymentCostClassName.mtcustom} ${paymentCostClassName.totalPaymentShade}`}
                        >
                          <Grid container className={classes.mb2}>
                            <Grid item md={7} className={classes.floatLeft}>
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.totalValue}
                              >
                                Same As Cash Total
                              </Typography>
                            </Grid>

                            <Grid item md={5}>
                              <Grid className={classes.floatRight}>
                                <Typography
                                  className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                                >
                                  $
                                </Typography>
                                <CurrencyInput
                                  id="input-example"
                                  name=""
                                  style={{ zIndex: 0, textAlign: 'right' }}
                                  className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`}
                                  decimalsLimit={2}
                                  disabled={true}
                                  data-testid="ProcessingFeeTxtBoxTest"
                                  value={sacAmount}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            className={paymentCostClassName.alignCenter}
                          >
                            <Grid item md={7}>
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.totalValue}
                              >
                                Agreement Total
                              </Typography>
                            </Grid>
                            <Grid item md={5}>
                              {!pageLoading ? (
                                <Typography
                                  variant="subtitle1"
                                  className={
                                    paymentCostClassName.agreementTotal
                                  }
                                >
                                  ${' '}
                                  {formatMoney(
                                    totalPaymentAmount.AgreementTotal
                                  )}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="subtitle1"
                                  className={
                                    paymentCostClassName.agreementTotal
                                  }
                                >
                                  <Skeleton
                                    count={1}
                                    baseColor="#FFFFFF"
                                    highlightColor="#e7e7e7"
                                  />
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Card>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.fixedBottom}>
              <RACButton
                className={`${classes.mx1} ${classes.floatLeft}`}
                variant="outlined"
                color="primary"
                onClick={() => PreviousBtnClick()}
                disabled={previousBtnDisabled}
              >
                Previous
              </RACButton>
              <Grid style={{ float: 'right' }}>
                <RACButton
                  className={`${classes.me2} ${classes.floatRight}`}
                  variant="contained"
                  color="primary"
                  onClick={() => NextBtnClick()}
                  disabled={nextBtnDisabled}
                >
                  Next
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
